interface WorkFunction {
  id: number;
  name: string,
  description: string
}
export const WORKFUNCTIONLIST: Array<WorkFunction> = [
  {
    id: 0,
    name: 'Development',
    description: 'Software Engineer, Web Developer, Front-end Developer, Back-end Developer, Mobile App Developer, Full-stack Developer, Embedded Systems Developer, Game Developer, Algorithm Engineer'
  },
  {
    id: 1,
    name: 'Management',
    description: 'Product Manager, Project Manager, Scrum Master'
  },
  {
    id: 2,
    name: 'Testing and QA',
    description: 'QA Engineer, Software Tester'
  },
  {
    id: 3,
    name: 'Design',
    description: 'UX/UI Designer, Graphic Designer, UI/UX Researcher'
  },
  {
    id: 4,
    name: 'DevOps and Infrastructure',
    description: 'DevOps Engineer, Cloud Solutions Architect, Release Engineer, Network Engineer, Network Administrator'
  },
  {
    id: 5,
    name: 'Data and Analytics',
    description: 'Data Scientist, AI/Machine Learning Engineer, Algorithm Engineer'
  },
  {
    id: 6,
    name: 'Security',
    description: 'Security Engineer, Information Security Analyst'
  },
  {
    id: 7,
    name: 'Support',
    description: 'Technical Support Engineer, Help Desk Specialist'
  },
  {
    id: 8,
    name: 'Documentation',
    description: 'Technical Writer'
  },
  {
    id: 9,
    name: 'Business Analysis',
    description: 'Business Analyst'
  },
  {
    id: 10,
    name: 'Systems Administration',
    description: 'Systems Administrator'
  },
  {
    id: 11,
    name: 'Sales and Marketing',
    description: 'Sales Engineer, Technical Sales Representative, Digital Marketing Specialist'
  },
  {
    id: 12,
    name: 'Executive',
    description: ''
  },
];