import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimeTrackingService } from './time-tracking.service';
import { secondsToTime2 } from 'app/utils/seconds-to-time';

@Injectable({
  providedIn: 'root'
})
export class TimeTitleStrategyService extends TitleStrategy {
  private readonly title = inject(Title);
  private readonly timeTrackingService = inject(TimeTrackingService);
  subscription: Subscription | null = null;

  get duration$() {
    return this.timeTrackingService.duration$;
  }

  updateTitle(snapshot: RouterStateSnapshot): void {
    this.subscription?.unsubscribe();
    const title = this.buildTitle(snapshot) || '';
    this.subscription = this.duration$.subscribe(v => {
      if (this.timeTrackingService.isPlayValue) {
        this.title.setTitle(`▶ ${secondsToTime2(v)} ${title}`);
      } else {
        this.title.setTitle(title);
      }
    })
  }
}
