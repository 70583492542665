<fuse-card class="w-full h-full flex-col p-6">
  <div class="flex items-start justify-between">
    <div class="text-lg font-medium">{{title}}</div>
    <div class="ml-2">
      <div class="h-6 min-h-6 px-2 rounded-full bg-hover" mat-button>
        <span class="font-medium text-sm text-secondary whitespace-nowrap">last 7 days</span>
      </div>
    </div>
  </div>
  <ng-container [ngTemplateOutlet]="(isLoadData$ | async) ? chart : skeleton"></ng-container>
</fuse-card>

<ng-template #skeleton>
  <div class="mt-4 flex flex-col gap-4">
    <div class="flex justify-center">
      <ngx-skeleton-loader [appearance]="'circle'" *ngIf="skeletonConfigCircle$ | async as skeletonConfigCircle"
        [theme]="skeletonConfigCircle.theme" [animation]="skeletonConfigCircle.animation">
      </ngx-skeleton-loader>
    </div>
    <ngx-skeleton-loader count="3" *ngIf="skeletonConfig$ | async as skeletonConfig" [theme]="skeletonConfig.theme"
      [animation]="skeletonConfig.animation">
    </ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #chart>
  <div class="flex justify-center" *ngIf="!chartData.tooltip.enabled">
    <div class="text-xl font-semibold text-secondary mt-4">No data found!</div>
  </div>
  <div class="mt-4 h-full flex items-center justify-center">
    <apx-chart class="w-full h-50 px-4"
      [chart]="chartData.chart"
      [labels]="chartData.labels"
      [series]="chartData.series"
      [colors]="chartData.colors"
      [tooltip]="chartData.tooltip"
      [legend]="chartData.legend"
      [noData]="chartData.noData"></apx-chart>
  </div>
  <div class="mt-8" *ngIf="chartData.labels.length > 0">
    <div class="-my-3 divide-y">
      <ng-container *ngFor="let dataset of chartData.labels; let i = index">
        <div class="grid grid-cols-3 py-3">
          <div class="flex items-center col-span-2" [matTooltip]="chartData.labels[i]">
            <div class="flex-0 w-2 h-2 rounded-full"
              [style.backgroundColor]="chartData.colors[i]"></div>
            <div class="ml-3 truncate">{{chartData.labels[i]}}</div>
          </div>
          <div class="font-medium text-right">{{chartData.series[i] | secondsToTime}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>