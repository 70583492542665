import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationCompleteComponent } from './pages/registration-complete/registration-complete.component';
import { map } from "rxjs";
import { OrganizationService } from 'app/services/organization.service';
import { UserService } from 'app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCompletedGuard implements CanDeactivate<RegistrationCompleteComponent>, CanActivate {

  constructor(
    private readonly userService: UserService,
    private readonly snackBar: MatSnackBar,
    private readonly organizationService: OrganizationService,
    private readonly router: Router) { }

  canDeactivate(component: RegistrationCompleteComponent): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.submit$.pipe(
      map(isSubmit => {
        if (!isSubmit) {
          this.snackBar.open('Please, fill out the form!', '', { duration: 5000 });
          return false;
        }
        return true;
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const userId = this.userService?.userId;
    if (userId) {
      return this.organizationService.viewUser(this.userService.userId).pipe(
        map((user) => {
          if (!user?.onboarding?.completed) {
            return true;
          } else this.router.navigateByUrl('user');
        })
      );
    } else this.router.navigateByUrl('/');

  }

}
