import { ObjectKind } from "app/services/objects-session"

export interface ClientState {
  client: {
    status: ClientStatus,
    session?: {
      id: string,
      objectId: string,
      objectKind: ObjectKind,
      startedAt: string
    } | null,
    timeWorked: number,
    committedAt: string
    version: string
  }
}

export enum ClientStatus {
  Started = 'Started',
  Stopped = 'Stopped'
}