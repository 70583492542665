import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { TeamUser } from 'app/models/user/user';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly organizationService: OrganizationService
  ) { }

  // get users$(): Observable<Array<TeamUser>> {
  //   return this._users$.asObservable();
  // }

  get(): Observable<Array<TeamUser>> {
    return this.httpClient.get<{ list: Array<TeamUser> }>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users`).pipe(
      map((users) => users.list),
      map(users => users.map(user => ({ ...user, displayName: user.displayName ?? `User-${user.id.split('-')[0]}` }))),
      // tap(users => this._users$.next(users)),
    )
  }
}
