<div class="relative cursor-pointer" [matTooltip]="tooltip$ | async" routerLink="./user/work-history">
  <div class="px-4 rounded-full flex gap-1 items-center border border-primary py-1"
    [ngClass]="{'border-2 border-red-500': errors$ | async, 'bg-primary-300 dark:bg-primary': isPlay$ | async}" [matTooltip]="errors$ | async">
    <ng-container *ngIf="(state$ | async); else spinnerTmpl">
      <!-- <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopTmpl : playTmpl"></ng-container> -->
      <div class="w-18 flex justify-center items-center font-semibold leading-4">
        <div>{{duration$ | async | secondsToTimePipe}}</div>
      </div>
      <!-- <mat-icon class="cursor-pointer icon-size-5 text-accent-600" (click)="openModal()"
        svgIcon="mat_outline:open_in_new" [matTooltip]="'Resize'"></mat-icon> -->
    </ng-container>
  </div>
</div>

<!-- <ng-template #timeTrackerDialog>
  <div class="flex flex-col gap-2">
    <div class="flex justify-between items-center">
      <div class="text-2xl cursor-move" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".theme-default" [cdkDragFreeDragPosition]="dragPosition$ | async" (cdkDragEnded)="dragEnded($event)">Time Tracking</div>
      <button mat-icon-button class="ml-auto" matDialogClose>
        <mat-icon svgIcon="mat_solid:close"></mat-icon>
      </button>
    </div>
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-1">
        <div class="flex flex-col">
          <mat-form-field class="w-full fuse-mat-dense" subscriptSizing="dynamic">
            <mat-label>Project or Task</mat-label>
            <mat-select [formControl]="objectFC" [compareWith]="compare">
              <mat-optgroup [label]="objects.key" *ngFor="let objects of objects$ | async | keyvalue">
                <mat-option [value]="{id: object.id, objectKind: object.objectKind}"
                  *ngFor="let object of objects.value">{{object.title}}</mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error>Select Project or Task!</mat-error>
          </mat-form-field>

          <div *ngIf="isPlayValue" class="text-secondary font-semibold text-md ml-auto">
            {{durationActive$ | async | secondsToTimePipe}}
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center gap-4 relative">
        <div
          class="w-40 h-40 rounded-full spinner-placeholder border-solid border-slate-100 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        </div>
        <mat-progress-spinner [color]="'primary'" [mode]="'determinate'" [value]="duration$ | async | secondsToPercent"
          [diameter]="160" strokeWidth="15">
        </mat-progress-spinner>
        <div
          class="flex flex-col items-center font-semibold text-secondary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 leading-6">
          <span class="text-md">Worked Today:</span>
          <span class="text-3xl">{{duration$ | async | secondsToTimePipe}}</span>
        </div>
      </div>
      <div class="flex justify-center">
        <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopDialogTmpl : playDialogTmpl"></ng-container>
      </div>
    </div>
    <div *ngIf="isSupportApp" class="flex">
      <a class="ml-auto" mat-icon-button href="spectrotime://launch" matTooltip="Open SpectroTime">
        <mat-icon svgIcon="apps"></mat-icon>
      </a>
    </div>
  </div>
</ng-template> -->

<!-- <ng-template #playDialogTmpl>
  <button mat-fab (click)="play()" [disabled]="objectFC.invalid">
    <mat-icon class="icon-size-10" svgIcon="mat_solid:play_arrow" [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopDialogTmpl>
  <button mat-fab (click)="stop()">
    <mat-icon class="icon-size-10" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #playTmpl>
  <mat-icon (click)="play()" class="cursor-pointer" svgIcon="mat_solid:play_arrow"
    [matTooltip]="'Start tracking'"></mat-icon>
</ng-template>

<ng-template #stopTmpl>
  <mat-icon (click)="stop()" class="cursor-pointer" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
</ng-template> -->

<ng-template #spinnerTmpl>
  <div class="flex flex-auto justify-center py-0.5">
    <mat-spinner diameter="15"></mat-spinner>
  </div>
</ng-template>