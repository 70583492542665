<div class="flex flex-col gap-2">
  <div class="flex items-center justify-between mb-4">
    <div class="text-2xl leading-tight">Invite to team</div>
    <button mat-icon-button matDialogClose><mat-icon svgIcon="mat_solid:close"></mat-icon></button>
  </div>
  <div [formGroup]="inviteForm">
    <div formArrayName="members" class="flex flex-col gap-3">
      <ng-container *ngFor="let member of getFormMembers['controls']; let i = index">
        <div class="flex flex-col gap-2" formGroupName="{{i}}">
          <div class="flex flex-col sm:flex-row sm:items-start gap-1 flex-auto">
            <mat-form-field [subscriptSizing]="'dynamic'" class="flex-auto fuse-mat-dense">
              <mat-label>Select project</mat-label>
              <mat-select formControlName="projectId">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let project of projects$ | async" [value]="project.id">{{project.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <mat-form-field [subscriptSizing]="'dynamic'" class="w-full sm:w-30 fuse-mat-dense">
                <mat-label>Select role</mat-label>
                <mat-select formControlName="projectRole">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option value="Member">Member</mat-option>
                  <mat-option value="Manager">Manager</mat-option>
                </mat-select>
                <mat-error>Role is requred!</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex flex-col md:flex-row md:items-center gap-1 flex-auto">
            <mat-form-field [subscriptSizing]="'dynamic'" class="flex-auto fuse-mat-dense">
              <mat-label>Enter email address</mat-label>
              <input placeholder="Enter email address" matInput formControlName="email" cdkFocusInitial>
            </mat-form-field>
            <mat-form-field [subscriptSizing]="'dynamic'" class="flex-auto fuse-mat-dense">
              <mat-label>Enter a Name</mat-label>
              <input placeholder="Enter Name" matInput formControlName="displayName">
            </mat-form-field>
            <div class="relative md:top-3">
              <button mat-icon-button (click)="copyMember(i)">
                <mat-icon svgIcon="mat_outline:content_copy"></mat-icon>
              </button>
              <button mat-icon-button (click)="removeMember(i)" *ngIf="getFormMembers.length > 1">
                <mat-icon svgIcon="mat_outline:remove_circle"></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-divider *ngIf="getFormMembers.length > 1 && getFormMembers.length - 1 > i"></mat-divider>
      </ng-container>
    </div>
  </div>
    <button mat-stroked-button color="primary" (click)="addNewMember()" class="self-start">
      <mat-icon svgIcon="heroicons_solid:user-add"></mat-icon>
      <span class="ml-2">Add more</span>
    </button>
  <div class="flex flex-col sm:flex-row">
    <button class="sm:ml-auto min-w-30" mat-raised-button color="primary"
      [disabled]="inviteForm.invalid || (submitting$ | async)" (click)="submit()">
      <span *ngIf="!(submitting$ | async); else spinner">Send invites</span>
      <ng-template #spinner>
        <mat-spinner diameter="25"></mat-spinner>
      </ng-template>
    </button>
  </div>
</div>