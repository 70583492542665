import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap, of } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from './user.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserGlobalRole } from 'app/models/user/user';
import { ProjectRoles } from 'app/models/project/project';
import { UserOrganizationRole } from 'app/models/organization/organization';

const navigationSuperAdmin: FuseNavigationItem = {
  icon: "",
  id: "admin-app",
  link: "",
  title: "Admin App",
  type: "group",
  children: [
    {
      icon: "mat_outline:dashboard",
      id: "admin-app.dashboard",
      link: "/admin-app/dashboard",
      title: "Dashboard",
      type: "basic",
    },
    {
      icon: "mat_outline:list_alt",
      id: "admin-app.organizations-list",
      link: "/admin-app/organizations-list",
      title: "Organizations List",
      type: "basic",
    }
  ]
};

const navigation: FuseNavigationItem = {
  icon: "",
  id: "navigation",
  link: "",
  type: "group",
  children: [
    {
      icon: "mat_outline:history",
      id: "work-history",
      link: "/user/work-history",
      title: "Work History",
      type: "basic",
      exactMatch: true,
    },
    {
      icon: "heroicons_solid:table",
      id: "my-activity",
      link: "/user/reports/simple",
      title: "Time Reports",
      type: "basic",
    },
    {
      icon: "heroicons_outline:camera",
      id: "screenshots",
      link: "/screenshots",
      title: "Screenshots",
      type: "basic",
    },
    {
      icon: "playlist_add_check",
      id: "tasks",
      link: "/tasks",
      title: "Tasks",
      type: "basic",
      exactMatch: true
    },
  ]
};

const navigationOwner: Array<FuseNavigationItem> = [
  {
    type: "divider",
  },
  {
    icon: "mat_outline:dashboard",
    id: "admin.dashboard",
    link: "/organization/dashboard",
    title: "Dashboard",
    type: "basic",
  },
  {
    icon: "heroicons_outline:briefcase",
    id: "projects",
    link: "organization/projects",
    title: "Projects",
    type: "basic",
    exactMatch: true
  },
  {
    icon: "monitoring",
    id: "organization-reports",
    title: "Reports",
    type: "collapsable",
    exactMatch: true,
    children: [
      {
        icon: "table_view",
        id: "organization-list",
        link: "/organization/reports/activity",
        title: "Activity",
        type: "basic",
      },
    ]
  },
  {
    icon: "groups",
    id: "people",
    link: "organization/people",
    title: "People",
    type: "basic",
  },
  {
    icon: "mat_solid:table_view",
    id: "audit-logs",
    link: "organization/audit-logs",
    title: "Audit Logs",
    type: "basic",
  },
];

const navigationList: Navigation = {
  compact: [],
  futuristic: [],
  horizontal: [],
  default: [],
  footer: [
    // {
    //   type: "divider",
    // },
    {
      icon: "mat_outline:settings",
      id: "settings",
      title: "Settings",
      type: "basic",
      link: "/settings",
      exactMatch: true,
    }
  ]
};

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get(role: UserOrganizationRole | UserGlobalRole | ProjectRoles): Observable<Navigation> {
    switch (role) {
      case UserGlobalRole.SuperAdmin:
        navigationList.default = [navigationSuperAdmin, ...navigationOwner, navigation];
        break;
      case UserOrganizationRole.Owner:
      case UserOrganizationRole.Viewer:
        navigationList.default = [navigation, ...navigationOwner];
        break;
      case UserOrganizationRole.Member:
        navigationList.default = [navigation];
        break;
    }
    return of(navigationList).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    );
  }
}