import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ClientState, ClientStatus } from 'app/models/client/client-state';
import { NewSession, Sessions } from 'app/models/sessions/session';
import { OrganizationService } from './organization.service';
import { map, tap } from 'rxjs';
import { TimeTrackingService } from './time-tracking.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private _httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly organizationService: OrganizationService) { }

  clientState(userId: string) {
    return this._httpClient.get<ClientState>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/clients/${userId}/state`);
  }

  getSessions(start?: string, end?: string) {
    let params = new HttpParams();
    if (start) {
      params = params.append('startTime', start);
    }
    if (end) {
      params = params.append('endTime', end);
    }
    return this._httpClient.get<Sessions>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions`, { params }).pipe(
      map(data => data.list)
    );
  }

  addSession(session: NewSession) {
    return this._httpClient.post(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions`, session);
  }

  changeSession(id: string, session: NewSession) {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions/${id}`, session);
  }

  removeSession(id: string) {
    return this._httpClient.delete(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions/${id}`);
  }
}
