import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'tm-subscribtions',
  templateUrl: './subscribtions.component.html',
  styleUrls: ['./subscribtions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribtionsComponent {
  yearlyBilling: boolean = true;
}
