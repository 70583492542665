import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig, Scheme } from 'app/core/config/app.config';
import { takeUntil, Subject } from "rxjs";

@Component({
  selector: 'tm-scheme-switcher',
  templateUrl: './scheme-switcher.component.html',
  styleUrls: ['./scheme-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchemeSwitcherComponent implements OnInit, OnDestroy {
  config: AppConfig;
  private readonly destroy$ = new Subject<void>();
  constructor(private _fuseConfigService: FuseConfigService) {
  }

  setScheme(scheme: Scheme): void {
    this._fuseConfigService.config = { scheme };
    localStorage.setItem('scheme', scheme);
  }

  ngOnInit(): void {
    this._fuseConfigService.config$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((config: AppConfig) => {
      this.config = config;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}