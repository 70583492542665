import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, defer, delay, filter, map, retry, shareReplay, switchMap, tap } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { ObjectKind } from './objects-session';
import { OrganizationService } from './organization.service';
import { UUID } from 'angular2-uuid';
import { DateTime } from 'luxon';
import { Title } from '@angular/platform-browser';
import { secondsToTime, secondsToTime2 } from 'app/utils/seconds-to-time';
import { ActivatedRoute, ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private _title = '';
  private _activeSessionStart: string = new Date().toJSON();
  private _worker: Worker;
  static DELTA = 60;
  private readonly _isPlay$ = new BehaviorSubject<boolean>(false);
  private _timeWorkedState = 0;
  sessionId: string | null = null;
  private readonly _duration$ = new BehaviorSubject<number>(0);
  private readonly _durationLocal$ = new BehaviorSubject<number>(0);
  private readonly _error$ = new BehaviorSubject<string | undefined>(undefined);
  private readonly _currentDate$ = new BehaviorSubject<DateTime>(DateTime.now());

  constructor(
    private readonly signalService: SignalRService,
    private readonly organizationService: OrganizationService,
    private readonly title: Title,
    private readonly router: Router
  ) {
    this.initSW();

    // this.router.events.pipe(
    //   filter(events => events instanceof NavigationEnd),
    //   map(navigationEnd => navigationEnd['urlAfterRedirects'].split('/')),
    //   map((segments: Array<string>) => segments[segments.length - 1]),
    //   map((title: string) => `${title.at(0).toUpperCase()}${title.substring(1)}`),
    //   // delay(1000)
    // ).subscribe((title) => {
    //   // this._title = title;
    //   // console.log(title)
    // });
  }

  get duration$() {
    return this._duration$.asObservable().pipe(shareReplay(1));
  }

  readonly durationActive$ = this.currentDate$.pipe(
    map(currentDate => currentDate.diff(DateTime.fromISO(this._activeSessionStart), 'seconds').seconds),
    filter(duration => duration > 0)
  );

  get error$() {
    return this._error$.asObservable().pipe(
      filter(error => !!error),
      shareReplay(1)
    );
  }
  get currentDate$() {
    return this._currentDate$.pipe(
      shareReplay(1)
    );
  }
  get isPlay$() {
    return this._isPlay$.asObservable().pipe(
      shareReplay(1)
    );
  }
  get isPlayValue() {
    return this._isPlay$.value;
  }
  get isConnected$() {
    return this.signalService.isConnected$;
  }
  get isReconnected$() {
    return this.signalService.isReconnected$;
  }
  get clientStarted$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, startedAt: string, connectId: string }>((observer) => {
        this.signalService.hubConnection.on('ClientStarted', (orgId, sessionId, objectId, objectKind, startedAt, connectId) => {
          observer.next({ orgId, sessionId, objectId, objectKind, startedAt, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get clientStopped$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, stoppedAt: string, timeWorked: number, connectId: string, }>((observer) => {
        this.signalService.hubConnection.on('ClientStopped', (orgId, sessionId, stoppedAt, timeWorked, connectId) => {
          observer.next({ orgId, sessionId, stoppedAt, timeWorked, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get clientTimeWorkedUpdated$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, timeWorked: number, commitedAtt: string }>((observer) => {
        this.signalService.hubConnection.on('ClientTimeWorkedUpdated', (orgId, timeWorked, commitedAtt) => {
          observer.next({ orgId, timeWorked, commitedAtt });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get clientSessionObjectChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, connectId: string }>((observer) => {
        this.signalService.hubConnection.on('ClientSessionObjectChanged', (orgId, sessionId, objectId, objectKind, connectId) => {
          observer.next({ orgId, sessionId, objectId, objectKind, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }

  get sessionCreated$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionCreated', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get sessionSubjectChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionSubjectChanged', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get sessionResized$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionResized', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get sessionSwitched$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionSwitched', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }
  get sessionDeleted$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionDeleted', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay(1)
    );
  }



  private startSession(objectId: string, objectKind: ObjectKind, sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('StartSession', orgId, objectId, objectKind, sessionId, date) ?? Promise.reject(new Error('not connection'));
  }

  private endSession(sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('EndSession', orgId, sessionId, date) ?? Promise.reject(new Error('not connection'));
  }

  private commitSession(sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('CommitTime', orgId, sessionId, date) ?? Promise.reject(new Error('not connection'));
  }

  сhangeObject(objectId: string, objectKind: ObjectKind): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('ChangeObject', orgId, this.sessionId, objectId, objectKind) ?? Promise.reject(new Error('not connection'));
  }

  init(timeWorked: number, date: string) {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
  }

  play(objectId: string, objectKind: ObjectKind, date: string) {
    this._activeSessionStart = date;
    this.sendToWorker('stop', date);
    this.sessionId = UUID.UUID();
    this._isPlay$.next(true);
    this.sendToWorker('start', date);
    this.startSession(objectId, objectKind, this.sessionId, date).then((data: { isSusscess: boolean, statusCode: number, error?: { title: string, detail: string } }) => {
      if (data.statusCode === 409) {
        this._isPlay$.next(false);
        this.sendToWorker('stop', date);
        this._duration$.next(this._timeWorkedState);
        this._error$.next('409: conflict');
      }
    }).catch((err) => {
      this._isPlay$.next(false);
      this.sendToWorker('stop', date);
      this._duration$.next(this._timeWorkedState);
      this._error$.next(err);
    });
  }

  stop(date: string) {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    this.endSession(this.sessionId, date).then(
      () => {
        this.sessionId = null;
        this._timeWorkedState = this._duration$.value;
      }).catch((err) => this._error$.next(err));
  }

  private commit() {
    const commitDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
    this.commitSession(this.sessionId, commitDate);
  }

  private sendToWorker(event: 'start' | 'stop', data: string) {
    this._worker.postMessage({ event, date: data });
  }

  run(sessionId: string, committedAt: string, starttedAt?: string) {
    this._activeSessionStart = starttedAt || committedAt;
    this.sendToWorker('stop', committedAt);
    this.sessionId = sessionId;
    this._isPlay$.next(true);
    const timeDifference = DateTime.now().set({ millisecond: 0 }).toUTC().diff(DateTime.fromISO(committedAt), 'seconds').seconds;
    this._duration$.next(timeDifference + this._timeWorkedState);
    this.sendToWorker('start', committedAt);
  }

  updateDuration(timeWorked: number) {
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked + this._durationLocal$.value);
  }

  private initSW() {
    if (typeof Worker !== 'undefined') {
      this._worker = new Worker(new URL('/src/app/time-tracking.worker', import.meta.url), { name: 'TimeTracking', type: 'module' });
      this._worker.onerror = (error) => {
        console.error('An error occurred in the worker:', error);
      };
      this._worker.addEventListener('message', (message: MessageEvent) => {
        const duration = message.data;
        this.tick(duration);
      });
    } else {
      this._error$.next('web worker not supported!')
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  private tick(duration: number) {
    this._currentDate$.next(DateTime.now());
    const duarationAll = this._timeWorkedState + duration;
    // this.title.setTitle(`${this._title}: ⌚ ${secondsToTime2(duarationAll)}`);
    this._duration$.next(duarationAll);
    if (duration % TimeTrackingService.DELTA === 0)
      this.commit();
  }
}