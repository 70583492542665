import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators, FormGroup } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { CommonModule } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { WORKFUNCTIONLIST } from 'app/models/work_function/work-function-list';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'tm-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class RegistrationCompleteComponent {
  readonly user$ = this.userService.user$;
  private user = this.userService.user;
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  readonly submitting$ = new BehaviorSubject<boolean>(false);
  readonly submit$ = new BehaviorSubject<boolean>(false);
  readonly workFunctionList = WORKFUNCTIONLIST;
  readonly userForm = this.fb.group({
    step1: this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      displayName: [this.user?.displayName, [Validators.required, Validators.minLength(1)]]
    }),
    step2: this.fb.group({
      role: [null, [Validators.required]]
    })
  });

  get step1FG(): FormGroup {
    return this.userForm.get('step1') as FormGroup;
  }

  get step2FG(): FormGroup {
    return this.userForm.get('step2') as FormGroup;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly userService: UserService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private readonly snackBar: MatSnackBar,) {
  }

  signOut(): void {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  submit(): void {
    this.submitting$.next(true);
    const profile = {
      firstName: this.step1FG.get('firstName').value,
      lastName: this.step1FG.get('lastName').value,
      displayName: this.step1FG.get('displayName').value,
    };
    const jobRole = this.step2FG.get('role').value;
    const onboard = {
      completed: true,
      skipped: false,
      completedSteps: ['profile: create']
    };
    forkJoin({
      profile: this.userService.updateProfile(profile),
      jobRole: this.userService.updateJobRole(jobRole),
      onboard: this.userService.completeOnboard(onboard)
    }).subscribe({
      next: () => {
        this.submit$.next(true);
        this.router.navigateByUrl('user');
      },
      error: () => {
        this.submit$.next(false);
        this.submitting$.next(false);
        this.snackBar.open('Error!', 'Ok', { duration: 15000 })
      }
    });
  }
}