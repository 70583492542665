import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { UserSelectionComponent } from './components/user-selection/user-selection.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { InviteConfirmComponent } from './pages/invite-confirm/invite-confirm.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { InputFocusDirective } from './directives/input-focus.directive';
import { ActivityDataTable2Component } from './components/activity-data-table2/activity-data-table2.component';
import { LoginFailedComponent } from './pages/login-failed/login-failed.component';
import { FuseCardModule } from '@fuse/components/card';
import { RouterModule } from '@angular/router';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { TimeAndActivityUserReportComponent } from './components/time-and-activity-user-report/time-and-activity-user-report.component';
import { TopFiveChartComponent } from './components/charts/top-five-chart/top-five-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { NGX_SKELETON_LOADER_CONFIG, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig } from 'app/core/config/app.config';
import { FuseAlertModule } from '@fuse/components/alert';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { DateLocalePipe } from './pipes/date-locale.pipe';
import { AddNewProjectComponent } from 'app/modules/organization/modules/projects/components/add-new-project/add-new-project.component';


@NgModule({
  declarations: [
    ActivityDataTable2Component,
    SecondsToTimePipe,
    InviteConfirmComponent,
    LoginFailedComponent,
    DateRangePickerComponent,
    TimeAndActivityUserReportComponent,
    TopFiveChartComponent,
    BarChartComponent,
    SkeletonLoaderComponent,
    OnboardingComponent,
    AccessDeniedComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    FuseCardModule,
    RouterModule,
    NgxSkeletonLoaderModule.forRoot(),
    FuseAlertModule,
    InputFocusDirective,
    DateLocalePipe,
    AddNewProjectComponent,
    UserSelectionComponent
  ],
  exports: [
    ActivityDataTable2Component,
    SecondsToTimePipe,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    InviteConfirmComponent,
    InputFocusDirective,
    LoginFailedComponent,
    FuseCardModule,
    DateRangePickerComponent,
    TimeAndActivityUserReportComponent,
    TopFiveChartComponent,
    BarChartComponent,
    SkeletonLoaderComponent,
    NgxSkeletonLoaderModule,
    FuseAlertModule,
    AccessDeniedComponent
  ],
  providers: [
    {
      provide: NGX_SKELETON_LOADER_CONFIG,
      useFactory: (fuseConfigService: AppConfig) => {
        const scheme = localStorage.getItem('scheme');
        return ({
          appearance: '',
          animation: (scheme) === 'light' ? 'progress' : 'progress-dark',
          theme: {
            extendsFromRoot: true,
            'background-color': (scheme) === 'light' ? '' : '#313C4E',
            'border-radius': '5px',
            'margin-bottom': '0',
            height: '45px'
          },
        })
      },
      deps: [FuseConfigService]
    }
  ]
})
export class CommonComponentsModule { }