import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, first, switchMap, of, filter, Observable, concat, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  constructor(private readonly appRef: ApplicationRef,
    private readonly updates: SwUpdate) { }

  get isLoadNewVersion$(): Observable<boolean> {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyOneMinutes$ = interval(60 * 1000);
    const everyOneMinutesOnceAppIsStable$ = concat(appIsStable$, everyOneMinutes$);
    return everyOneMinutesOnceAppIsStable$.pipe(
      switchMap(() => of(this.updates.isEnabled)),
      filter(isEnabled => isEnabled),
      switchMap(() => {
        try {
          return from(this.updates.checkForUpdate());
        } catch (err) {
          console.error('Failed to check for updates:', err);
          return of(false)
        }
      })
    );
  }
  // get isLoadNewVersion$(): Observable<boolean> {
  //   const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
  //   const everyOneMinutes$ = interval(1 * 1 * 60 * 1000);
  //   const everyOneMinutesOnceAppIsStable$ = concat(appIsStable$, everyOneMinutes$);
  //   return everyOneMinutesOnceAppIsStable$.pipe(
  //     switchMap(() => of(this.updates.isEnabled)),
  //     // tap(() => console.log(this.updates.isEnabled)),
  //     filter(isEnabled => isEnabled),
  //     switchMap(async () => {
  //       const isUpdateFound = await this.updates.checkForUpdate().catch((err) => {
  //         console.error('Failed to check for updates:', err);
  //         return false;
  //       });
  //       return isUpdateFound;
  //     }),
  //     switchMap((isUpdateFound) => (isUpdateFound) ? this.openSnackBar() : of(false)),
  //     filter(isLoadNewVersion => isLoadNewVersion === true),
  //     catchError(() => of(false)),
  //   );
  // }

  // private openSnackBar(): Observable<boolean> {
  //   const snackBarRef = this.snackBar.open(`Application has been updated! Click to 'Update application' to get refreshed!`, "Update application", {});
  //   return snackBarRef.onAction().pipe(
  //     map(() => true)
  //   );
  // }
}