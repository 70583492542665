<div class="absolute inset-0">
  <div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">

    <!-- Header & Cards -->
    <div class="relative pt-8 pb-4 sm:pt-20 sm:pb-4 px-6 sm:px-16 overflow-hidden">
      <!-- Background - @formatter:off -->
      <svg class="-z-1 absolute inset-0" viewBox="0 0 960 540" width="100%" height="100%"
        preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
        <g class="opacity-40 text-gray-200 dark:text-gray-800" fill="none" stroke="currentColor" stroke-width="100">
          <circle r="234" cx="196" cy="23"></circle>
          <circle r="234" cx="790" cy="491"></circle>
        </g>
      </svg>
      <!-- @formatter:on -->
      <!-- Header -->
      <div class="flex flex-col items-center">
        <h2 class="text-xl font-semibold">PRICING</h2>
        <div class="mt-1 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
          Take control of your productivity
        </div>
        <div class="mt-3 sm:text-2xl text-center tracking-tight text-secondary">
          <div class="">Start small and free, upgrade as you go.</div>
          <div class="">Take control of everything.</div>
        </div>
        <!-- Recurring selector -->
        <!-- <div class="flex items-center mt-8 sm:mt-16 p-0.5 rounded-full overflow-hidden bg-gray-200 dark:bg-gray-700">
          <button class="px-6" [ngClass]="{'bg-card pointer-events-none shadow': yearlyBilling,
                                'bg-transparent': !yearlyBilling}" mat-flat-button (click)="yearlyBilling = true"
            disableRipple>
            Yearly billing
          </button>
          <button class="px-6" [ngClass]="{'bg-card pointer-events-none shadow': !yearlyBilling,
                                'bg-transparent': yearlyBilling}" mat-flat-button (click)="yearlyBilling = false"
            disableRipple>
            Monthly billing
          </button>
        </div> -->
      </div>
      <!-- Cards -->
      <div class="flex justify-center mt-10 sm:mt-20">
        <div class="w-full max-w-sm md:max-w-4xl">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-y-6 lg:gap-y-0 md:gap-x-6">
            <!-- Card 1 -->
            <fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10">
              <div class="text-4xl font-bold tracking-tight leading-tight">Free Beta Trial</div>
              <div class="mt-2 text-lg font-medium tracking-tight text-secondary">6 month of Unlimited Use</div>
              <div class="w-8 h-1 my-10 rounded bg-accent"></div>
              <!-- Price -->
              <div class="flex items-baseline whitespace-nowrap">
                <div class="mr-2 text-2xl">EUR</div>
                <div class="text-6xl font-semibold leading-tight tracking-tight">
                  <ng-container *ngIf="yearlyBilling">€0.00</ng-container>
                  <ng-container *ngIf="!yearlyBilling">€0.00</ng-container>
                </div>
              </div>
              <!-- Price details -->
              <div class="flex flex-col mt-2 text-secondary">
                <ng-container *ngIf="yearlyBilling">
                  <div>billed yearly</div>
                  <div><b>€0.00</b> billed monthly</div>
                </ng-container>
                <ng-container *ngIf="!yearlyBilling">
                  <div>billed monthly</div>
                  <div><b>€0.00</b> billed yearly</div>
                </ng-container>
              </div>
              <!-- CTA -->
              <button class="fuse-mat-button-large mt-10" mat-stroked-button [color]="'primary'">
                Get Started
              </button>
              <!-- Features -->
              <div class="flex flex-col mt-12">
                <div class="font-semibold">Core features, including:</div>
                <div class="mt-4 space-y-2">
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">Unlimited users</div>
                  </div>
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">Unlimited tracking</div>
                  </div>
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">Unlimited projects</div>
                  </div>
                </div>
              </div>
            </fuse-card>
            <!-- Card 2 -->
            <fuse-card class="flex-col max-w-sm md:max-w-none p-6 sm:py-12 sm:px-10">
              <div class="text-4xl font-bold tracking-tight leading-tight">Small Team</div>
              <div class="mt-2 text-lg font-medium tracking-tight text-secondary">For Freelancers and Small Teams</div>
              <div class="w-8 h-1 my-10 rounded bg-accent"></div>
              <!-- Price -->
              <div class="flex items-baseline whitespace-nowrap">
                <!-- <div class="mr-2 text-2xl">EUR</div> -->
                <div class="text-6xl font-semibold leading-tight tracking-tight">
                  <ng-container *ngIf="yearlyBilling">Free Forever</ng-container>
                  <ng-container *ngIf="!yearlyBilling">Free Forever</ng-container>
                </div>
              </div>
              <!-- Price details -->
              <div class="flex flex-col mt-2 text-secondary">
                <ng-container *ngIf="yearlyBilling">
                  <div>billed yearly</div>
                  <div><b>€0.00</b> billed monthly</div>
                </ng-container>
                <ng-container *ngIf="!yearlyBilling">
                  <div>billed monthly</div>
                  <div><b>€0.00</b> billed yearly</div>
                </ng-container>
              </div>
              <!-- CTA -->
              <button class="fuse-mat-button-large mt-10" mat-stroked-button [color]="'primary'">
                Get Started
              </button>
              <!-- Features -->
              <div class="flex flex-col mt-12">
                <div class="font-semibold">Personal features, plus:</div>
                <div class="mt-4 space-y-2">
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">up to <b>5</b> people in the team</div>
                  </div>
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">up to <b>5</b> active projects</div>
                  </div>
                  <div class="flex">
                    <mat-icon class="icon-size-5 text-green-600" [svgIcon]="'heroicons_solid:check'"></mat-icon>
                    <div class="ml-2 leading-5">base reporting capacities</div>
                  </div>
                </div>
              </div>
            </fuse-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>