import { InjectionToken, inject } from "@angular/core";
import { ClientState } from "app/models/client/client-state";
import { debounceTime, merge, Observable, repeat, switchMap } from "rxjs";
import { ClientService } from "./client.service";
import { UserService } from "./user.service";
import { SignalRService } from "./signal-r.service";

export const CLIENT_STATE = new InjectionToken<Observable<ClientState>>('clientState',
  {
    factory: () => {
      const userService = inject(UserService);
      const clientService = inject(ClientService);
      const signalRService = inject(SignalRService);
      const state$ = userService.user$.pipe(
        switchMap((user) => clientService.clientState(user.id).pipe(
          repeat({ delay: () => merge(signalRService.isReconnected$, clientService.refreshState$.pipe(debounceTime(1000))) }),
        )),
        // shareReplay({ bufferSize: 1, refCount: true })
      );
      return state$;
    }
  }
);