import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { Tickets } from 'app/models/ticket/tickets';
import { Observable, ReplaySubject, Subject, defer, map, merge, of, repeat, shareReplay, switchMap, tap } from 'rxjs';
import { OrganizationService } from './organization.service';
import { Ticket, TicketStatus } from 'app/models/ticket/ticket';
import { UserService } from './user.service';
import { SignalRService } from './signal-r.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private readonly _tickets$ = new ReplaySubject<Array<Ticket>>(1);
  private readonly _refresh$ = new Subject<void>();
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly organizationService: OrganizationService,
    private readonly userService: UserService,
    private readonly signalService: SignalRService,) {
    this.get().subscribe();
  }

  get tickets$() {
    return this._tickets$.asObservable();
  }

  get isConnected$() {
    return this.signalService.isConnected$;
  }

  get ticketCreated$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('TicketCreated', () => {
          observer.next();
        });
      }))),
      shareReplay(1)
    );
  }

  get ticketTitleChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('TicketTitleChanged', () => {
          observer.next();
        });
      }))),
      shareReplay(1)
    );
  }

  get ticketStatusChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('TicketStatusChanged', () => {
          observer.next();
        });
      }))),
      shareReplay(1)
    );
  }

  get ticketAction$() {
    return merge(this.ticketCreated$, this.ticketTitleChanged$, this.ticketStatusChanged$)
  }

  refresh() {
    this._refresh$.next();
  }

  private get() {
    return this.userService.user$.pipe(
      map(user => user.id),
      switchMap(userId => {
        const params = new HttpParams().append('userId', userId);
        return this.httpClient.get<Tickets>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/tickets`, { params }).pipe(
          map(tasks => tasks.list),
          tap(tickets => this._tickets$.next(tickets)),
          repeat({ delay: () => merge(this._refresh$, this.ticketAction$) })
        )
      }),
    )
  }
}