import { InjectionToken, inject } from "@angular/core";
import { ClientState } from "app/models/client/client-state";
import { Observable, repeat, switchMap, take } from "rxjs";
import { ClientService } from "./client.service";
import { UserService } from "./user.service";
import { SignalRService } from "./signal-r.service";

export const CLIENT_STATE = new InjectionToken<Observable<ClientState>>('clientState',
  {
    factory: () => {
      const userService = inject(UserService);
      const clientService = inject(ClientService);
      const signalRService = inject(SignalRService);
      return userService.user$.pipe(
        switchMap((user) => clientService.clientState(user.id).pipe(repeat({ delay: () => signalRService.isReconnected$ }))),
      );
    }
  }
);