import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil, shareReplay, filter, switchMap } from 'rxjs';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user/user';
import { OrganizationService } from 'app/services/organization.service';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationAddComponent } from 'app/modules/organization/modules/settings/components/organization-add/organization-add.component';
import { AuthService } from '@auth0/auth0-angular';


@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
  @Input() showAvatar: boolean = true;
  user: User;
  private destroy$: Subject<any> = new Subject<void>();
  readonly organizations$ = this.organizationService.organizations$.pipe(shareReplay(1));

  get selectedOrganizationId(): string {
    return this.organizationService.organizationSelectedId;
  }

  constructor(
    private readonly organizationService: OrganizationService,
    private chr: ChangeDetectorRef,
    private _userService: UserService,
    private readonly dialog: MatDialog,
    private readonly auth0Service: AuthService) { }

  selectOrganization(id: string) {
    this.organizationService.select(id);
  }

  addOrganization(): void {
    const dialogRef = this.dialog.open(OrganizationAddComponent);
    dialogRef.afterClosed().pipe(
      filter(isUpdate => isUpdate),
      switchMap(() => this.organizationService.get())
    ).subscribe();
  }

  signOut(): void {
    this.auth0Service.logout();
  }

  ngOnInit(): void {
    this._userService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.chr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}