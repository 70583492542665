import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { BehaviorSubject, Subject, map, takeUntil, tap, catchError, of, Observable, merge, shareReplay, combineLatest, filter, take } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientState, ClientStatus } from 'app/models/client/client-state';
import { DateTime } from 'luxon';
import { isSupportApp } from 'app/utils/support-app';
import { CLIENT_STATE } from 'app/services/client.state';
import { OBJECTSSESSION, ObjectKind, ObjectKindUI, ObjectSession } from 'app/services/objects-session';
import { TimeTrackingService } from 'app/services/time-tracking.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SecondsToTimePipe } from 'app/shared/components/time-tracking-widget/pipes/seconds-to-time.pipe';
import { AddTaskComponent } from 'app/modules/user/components/add-task/add-task.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TicketService } from 'app/services/ticket.service';
import { SessionAddComponent } from 'app/modules/user/modules/work-history/components/session-add/session-add.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { SignalRService } from 'app/services/signal-r.service';

@Component({
  selector: 'tm-time-tracking-widget2',
  standalone: true,
  imports: [SecondsToTimePipe, CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, DragDropModule, MatSnackBarModule, DragDropModule],
  templateUrl: './time-tracking-widget2.component.html',
  styleUrls: ['./time-tracking-widget2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeTrackingWidget2Component implements OnInit, OnDestroy {
  @ViewChild('menuTr') private readonly mainMenuTr: MatMenuTrigger;
  readonly objectKindUI = ObjectKindUI;
  private readonly destroy$ = new Subject<void>();
  private readonly _error$ = new BehaviorSubject<string | null>(null);
  readonly isSupportApp = isSupportApp();
  readonly durationActive$ = this.timeTrackingService.durationActive$;
  readonly selectedObject$ = new BehaviorSubject<any>(null);
  startedAt = new Date().toISOString();

  objectsList = [];

  get currentDate() {
    return new Date().toISOString();
  }

  get ticketsList() {
    return this.objectsList.filter(object => object.objectKind === ObjectKind.Ticket)
  }
  get projectsList() {
    return this.objectsList.filter(object => object.objectKind === ObjectKind.Project)
  }

  get duration$() {
    return this.timeTrackingService.duration$;
  }

  readonly errors$ = merge(this.timeTrackingService.error$, this._error$).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  get isPlayValue() {
    return this.timeTrackingService.isPlayValue;
  }

  get isPlay$() {
    return this.timeTrackingService.isPlay$;
  }

  get objectId() {
    return this.selectedObject$.value?.id;
  }
  readonly isConnectedSignalR$ = this.signalRServce.isConnected$;

  readonly state$ = combineLatest({
    objectId: this.clientState$.pipe(
      map(state => state.client),
      map(({ session, status, timeWorked, committedAt }) => {
        this._error$.next(null);
        // this.timeTrackingService.init(timeWorked, committedAt);
        if (session) {
          // this.objectFC.setValue({ id: session.objectId, objectKind: session.objectKind }, { emitEvent: false });
          if (status === ClientStatus.Started) {
            // this.timeTrackingService.run(session.id, committedAt, session.startedAt);
            this.startedAt = DateTime.fromISO(session.startedAt).toISO();
          }
          return session.objectId
        }
        return null;
      })),
    objectsSessions: this.objectsSessions$.pipe(tap(objectsSessions => {
      this.objectsList = objectsSessions;
    }))
  }).pipe(
    tap(({ objectId, objectsSessions }) => {
      const selectedObject = objectsSessions.find(object => object.id === objectId);
      this.selectedObject$.next(selectedObject || { title: '' });
    }),
    catchError((err) => {
      const date = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
      this.timeTrackingService.init(0, date);
      this._error$.next('Error get state!');
      return of(true);
    })
  );

  constructor(
    @Inject(CLIENT_STATE) private readonly clientState$: Observable<ClientState>,
    @Inject(OBJECTSSESSION) readonly objectsSessions$: Observable<Array<ObjectSession>>,
    private readonly timeTrackingService: TimeTrackingService,
    private readonly dialog: MatDialog,
    private readonly ticketService: TicketService,
    private readonly snackBar: MatSnackBar,
    private readonly signalRServce: SignalRService,
    public readonly dialogRef: MatDialogRef<TimeTrackingWidget2Component>,
  ) { }

  play() {
    if (this.objectId) {
      const startDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
      this.timeTrackingService.play(this.objectId, this.selectedObject$.value?.objectKind, startDate).subscribe();
    } else this.snackBar.open('Select Project or Task', 'OK').onAction().pipe(
      take(1)
    ).subscribe(() => this.mainMenuTr.openMenu());
  }

  stop() {
    const endDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
    this.timeTrackingService.stop(endDate).subscribe();
  }

  compare(o1: ObjectSession, o2: ObjectSession) {
    return o1?.id === o2?.id;
  }

  changeObject(object: ObjectSession) {
    this.selectedObject$.next(object);
    if (this.isPlayValue) {
      this.timeTrackingService.сhangeObject(object.id, object.objectKind);
    }
  }

  createTicket(projectId?: string): void {
    (this.dialog.open(AddTaskComponent, {
      data: projectId
    }).addPanelClass(['min-w-80', 'max-w-full', 'w-full', 'h-full', 'sm:h-fit', 'sm:w-100', 'md:w-160'])
      .afterClosed()).pipe(
        takeUntil(this.destroy$),
        filter((status: 'success' | undefined) => status === 'success'),
      ).subscribe({
        next: () => this.ticketService.refresh()
      });
  }

  sessionNewAdd(date: number = Date.now()) {
    const data = {
      session: {
        startTime: DateTime.fromMillis(date).set({ hour: 8, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        endTime: DateTime.fromMillis(date).set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
      },
      type: 'add'
    };
    this.dialog.open(SessionAddComponent, { data }).addPanelClass(['min-w-80', 'max-w-full', 'w-full', 'h-full', 'sm:h-fit', 'sm:w-100', 'md:w-160']).afterClosed().pipe(
      filter(isUpdate => !!isUpdate)
    ).subscribe();
  }

  ngOnInit(): void {

    this.timeTrackingService.clientSessionObjectChanged$.pipe(
      takeUntil(this.destroy$),
      map(data => ({ sessionId: data.sessionId, status: ClientStatus.Started, objectKind: data.objectKind, objectId: data.objectId }))
    ).subscribe(({ objectId }) => {
      this.selectedObject$.next(this.objectsList.find(object => object.id === objectId));
    });


    this.timeTrackingService.clientStarted$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ objectId, sessionId, startedAt, objectKind }) => {
        // this.objectFC.setValue({ id: objectId, objectKind: objectKind }, { emitEvent: false });
        this.selectedObject$.next(this.objectsList.find(object => object.id === objectId));
        this.startedAt = DateTime.fromISO(startedAt).toISO();
        // if (!this.isPlayValue) {
        //   this.timeTrackingService.run(sessionId, startedAt);
        // }
      }
    });

    this.timeTrackingService.clientTimeWorkedUpdated$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ timeWorked }) => this.timeTrackingService.updateDuration(timeWorked)
    });

    this.timeTrackingService.clientStopped$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ sessionId, timeWorked, stoppedAt }) => {
        this.timeTrackingService.init(timeWorked, stoppedAt);
      },
      error: (err) => {
        this._error$.next(err);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}