import { Injectable } from '@angular/core';
import { UserService } from './services/user.service';
import { OrganizationService } from './services/organization.service';
import { NavigationService } from './services/navigation.service';
import { Observable, map, switchMap, tap, retry } from 'rxjs';
import { OrganizationView } from './models/organization/organization';
import { CanActivate, Router } from '@angular/router';
import { User } from './models/user/user';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(
    private readonly _userService: UserService,
    private readonly _organizationService: OrganizationService,
    private readonly _navigationService: NavigationService,
    private readonly router: Router,
  ) { }
  canActivate(): Observable<boolean> {
    return this._organizationService.getOrganizations().pipe(
      tap({
        next: (organizations) => {
          if (organizations?.length > 0) {
            this._organizationService.organizations = organizations;
            return;
          };
          this.router.navigate(['/company/complete-registration']);
        },
        error: (error) => {
          console.log(error)
          if (error instanceof HttpErrorResponse && (error.status === 500)) {
            this.router.navigate(['/errors/maintenance']);
          }
          return false;
        },
      }),
      switchMap(() => this._organizationService.organizationInit()),
      tap({
        next: (organization) => {
          if (!organization?.onboarding?.completed && organization?.isOwner) {
            // this.router.navigate(['/onboarding']);
          }
        },
      }),
      retry({ count: 1, delay: 1000 }),
      map((organization: OrganizationView) => {
        const user = organization.users?.length ? organization.users.at(0) : null;
        if (user) {
          return { user, organization };
        } else this.router.navigate(['access-denied'])
      }),
      switchMap(({ user, organization }) => this._organizationService.viewUser(user.id).pipe(
        tap((_user) => {
          const user = { ..._user, displayName: _user.displayName ?? `User-${_user.id.split('-')[0]}` };
          console.log(user);
          this._userService.setUser(user);
          this._userService.user = user;
          if (!user?.onboarding?.completed && !organization?.isOwner) {
            // this.router.navigate(['/user/complete-registration']);
          }
        })
      )),
      switchMap((user: User) => this._navigationService.get(user.role)),
      map(() => true)
    )
    // return this.authService.isAuthenticated$.pipe(

    // )
  }
}