import { Component, OnDestroy, ChangeDetectionStrategy, ViewEncapsulation, OnInit, HostBinding } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ProjectService } from 'app/services/project.service';
import { Subject, takeUntil, BehaviorSubject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { WorkCategoryColors } from 'app/models/category/category';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { ColorPickerModule } from 'ngx-color-picker';

@Component({
  selector: 'app-add-new-project',
  templateUrl: './add-new-project.component.html',
  styleUrls: ['./add-new-project.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, ColorPickerModule]
})
export class AddNewProjectComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  submitting$ = new BehaviorSubject<boolean | void>(false);
  projectForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    color: '',
    description: null
  });


  set color(v: string) {
    this.projectForm.get('color').setValue(v, { emitEvent: false });
  }

  get color(): string {
    return this.projectForm.get('color').value;
  }

  constructor(
    private dialogRef: MatDialogRef<AddNewProjectComponent>,
    private fb: FormBuilder,
    private projectSerivece: ProjectService) { }

  get formInValid(): boolean {
    return this.projectForm.invalid;
  }

  submit() {
    this.submitting$.next(true);
    const project = {
      name: this.projectForm.get('name').value,
      description: this.projectForm.get('description').value || null,
      color: parseInt((this.projectForm.get('color').value as string).replace('#', ''), 16) || 0
    }
    this.projectSerivece.addProjects(project).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => {
        this.submitting$.next(false);
        this.dialogRef.close('success')
      },
      error: () => {
        this.submitting$.next(false);
      }
    });
  }

  ngOnInit(): void {
    this.projectForm.get('color').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(color => {
      (document.querySelector(':root') as any).style.setProperty('--project-color', color);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.submitting$.next();
    this.submitting$.complete();
  }
}