import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, mergeMap } from 'rxjs/operators';
import { AppInfoComponent } from './modules/app-service/components/app-info/app-info.component';
import { UpdateAppService } from './services/update-app.service';
import { SignalRService } from './services/signal-r.service';
import { IsNewVersionMsgAppComponent } from './shared/components/is-new-version-msg-app/is-new-version-msg-app.component';
import { AuthService, GenericError } from '@auth0/auth0-angular';

@Component({
  selector: 'tm-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private auth0Service: AuthService,
    private snackbar: MatSnackBar,
    private readonly updateAppService: UpdateAppService,
    private readonly signalRService: SignalRService,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.snackbar.openFromComponent(AppInfoComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });

    this.updateAppService.isLoadNewVersion$.pipe(
      filter(isNewVersion => isNewVersion),
    ).subscribe(() => this.snackBar.openFromComponent(IsNewVersionMsgAppComponent));

    this.auth0Service.error$.pipe(
      filter((e) => e instanceof GenericError && e.error === 'login_required'),
      mergeMap(() => this.auth0Service.loginWithRedirect())
    ).subscribe();

    this.signalRService.connect().subscribe();

    this.auth0Service.user$.subscribe((u) => console.log(u));
  }
}