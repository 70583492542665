<div class="flex items-center justify-center w-full">
  <div class="flex p-6 flex-col w-full sm:w-140 rounded-none sm:rounded-3xl bg-white">
    <div class="flex justify-center mb-8">
      <img [src]="'./assets/images/logo/logo.svg'" class="w-14 h-14">
    </div>
    <div class="text-3xl font-semibold">User Details</div>
    <div class="my-4 text-lg tracking-tight leading-6 text-gray-500 text-center">
      By sharing your personal details, we can ensure that every interaction with our platform feels more tailored.
      Knowing
      your name and position helps us address you appropriately and cater to your specific needs.
    </div>
    <div [formGroup]="userForm">
      <mat-vertical-stepper #stepper [linear]="true">
        <mat-step [stepControl]="step1FG">
          <ng-template matStepLabel>Your Name</ng-template>
          <div class="text-secondary text-md font-normal my-4"></div>
          <div formGroupName="step1" class="flex flex-col gap-2 mb-6">
            <mat-form-field class="w-full">
              <mat-label>First Name</mat-label>
              <input [formControlName]="'firstName'" matInput cdkFocusInitial>
              <mat-hint>The given name you primarily go by</mat-hint>
              <mat-error>FirstName is required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
              <mat-label>Last Name</mat-label>
              <input [formControlName]="'lastName'" matInput>
              <mat-hint>Your family or surname, typically shared with family members</mat-hint>
              <mat-error>LastName is required</mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
              <mat-label>Display Name</mat-label>
              <input [formControlName]="'displayName'" matInput>
              <mat-hint>The name that will be publicly shown or used in our platform interactions</mat-hint>
              <mat-error>Display Name is required</mat-error>
            </mat-form-field>
          </div>
          <button [disabled]="step1FG.invalid" color="primary" mat-raised-button matStepperNext>Next</button>
        </mat-step>
        <mat-step [stepControl]="step2FG">
          <ng-template matStepLabel>Your Role/Position</ng-template>
          <div class="text-secondary text-md font-normal my-4"></div>
          <div formGroupName="step2">
            <mat-form-field class="w-full">
              <mat-label>Select your Role/Position</mat-label>
              <mat-select formControlName="role">
                <mat-option [value]="role.id" *ngFor="let role of workFunctionList">{{role.name}}</mat-option>
              </mat-select>
              <mat-error>Your Role/Position is required</mat-error>
            </mat-form-field>
          </div>
          <div class="mt-2">
            <button [disabled]="userForm.invalid || (submitting$ | async)" color="primary" mat-raised-button
              (click)="submit()">
              <div class="flex items-center gap-2">
                <ng-container *ngIf="submitting$ | async" [ngTemplateOutlet]="spinner"></ng-container>
                <div>Complete Registration</div>
              </div>
            </button>
          </div>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>

  <ng-template #spinner>
    <mat-spinner [color]="'primary'" diameter="20"></mat-spinner>
  </ng-template>


  <ng-template #text>
    By sharing your personal details, we can ensure that every interaction with our platform feels more tailored.
    Knowing
    your name and position helps us address you appropriately and cater to your specific needs.
  </ng-template>