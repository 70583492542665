import { Component, OnDestroy, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from 'app/services/project.service';
import { Subject, takeUntil, BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'app/services/users.service';
import { OrganizationService } from 'app/services/organization.service';
import { UserService } from 'app/services/user.service';

export interface ProjectData {
  projectId: string;
}

@Component({
  selector: 'tm-add-new-task',
  templateUrl: './add-new-task.component.html',
  styleUrls: ['./add-new-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewTaskComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  submitting$ = new BehaviorSubject<boolean>(false);
  readonly taskForm: FormGroup = this.fb.group({
    title: ['', [Validators.required]],
    description: null,
    assigneeId: null,
  });
  readonly users$ = this.usersService.get();

  constructor(
    private readonly usersService: UsersService,
    private readonly dialogRef: MatDialogRef<AddNewTaskComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly projectData: ProjectData,
    private readonly fb: FormBuilder,
    private readonly projectService: ProjectService) {
  }

  get formInValid(): boolean {
    return this.taskForm.invalid;
  }

  submit(): void {
    const projectId = this.projectData.projectId;
    this.submitting$.next(true);
    const data = this.taskForm.value;
    this.projectService.createTicket(projectId, data).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: () => this.dialogRef.close('success'),
      error: () => this.submitting$.next(false)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.submitting$.complete();
  }
}