import { InjectionToken, inject } from "@angular/core";
import { Observable, combineLatest, map, shareReplay } from "rxjs";
import { ProjectService } from "./project.service";
import { ProjectStatus } from "app/models/project/project";
import { TicketService } from "./ticket.service";
import { TicketStatus } from "app/models/ticket/ticket";

export interface ObjectSession {
  id: string,
  title: string,
  objectKind: ObjectKind
  category: string
}
export enum ObjectKind {
  Ticket = 'Ticket',
  Project = 'Project'
}

export enum ObjectKindUI {
  Ticket = 'Task',
  Project = 'Project'
}

export const OBJECTSSESSION = new InjectionToken<Observable<Array<ObjectSession>>>('OBJECTSSESSION',
  {
    factory: () => {
      // const userService = inject(UserService);
      // const organizationService = inject(OrganizationService);
      const projectService = inject(ProjectService);
      const ticketService = inject(TicketService);

      const getTickets = () => {
        return ticketService.tickets$.pipe(
          map(tickets => tickets.filter(ticket => ticket.status === TicketStatus.Open)),
          map(tickets => tickets.map(ticket => ({ id: ticket.id, title: ticket.title, objectKind: ObjectKind.Ticket, category: 'Tasks' })))
        );
      }

      const getProjects = () => {
        return projectService.userProjects$.pipe(
          map(projects => projects.filter(project => project.status === ProjectStatus.Normal)),
          map(projects => projects.map(project => ({ id: project.id, title: project.name, objectKind: ObjectKind.Project, category: 'Projects' })))
        );
      }
      return combineLatest({
        tickets: getTickets(),
        projects: getProjects(),
      }).pipe(
        map(({ tickets, projects }) => [...tickets, ...projects]),
        shareReplay(1)
      );
    }
  }
);