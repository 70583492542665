import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { AuthModule, AuthHttpInterceptor, } from "@auth0/auth0-angular";
import { environment } from "environments/environment";

@NgModule({
  imports: [
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      // useRefreshTokens: true,
      // useRefreshTokensFallback: true,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [`${environment.auth0.audience}/${environment.auth0.version}/*`]
      },
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
  ]
})
export class Auth0Module {
  constructor(@Optional() @SkipSelf() parentModule?: Auth0Module) {
    if (parentModule) {
      throw new Error('Auth0Module has already been loaded. Import this module in the AppModule only.');
    }
  }
}