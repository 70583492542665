export interface OrganizationCategory {
  id?: string,
  name: string,
  parentId?: string,
  organizationId?: string
}

export interface OrganizationCategoriesList {
  list: Array<OrganizationCategory>
}

export interface AppCategory {
  id: string,
  name: string,
}

export interface AppCategoriesList {
  list: Array<AppCategory>
}


export enum WorkCategory {
  'Core Work' = 1,
  'Supportive Work' = 2,
  'Communication and Meetings' = 3,
  'Learning' = 4,
  'Personal and Social' = 5,
  'Breaks' = 6,
  'Other' = 7,
  'Uncategorised' = 0,
  'Unknown' = 'Unknown',

  // 'Communication and Collaboration' = 1,
  // 'Productivity and Organization' = 2,
  // 'Document and File Management' = 3,
  // 'CRM and Sales' = 4,
  // 'Project Management and Planning' = 5,
  // 'Finance and Accounting' = 6,
  // 'Technical and Development Tools' = 7,
  // 'Data Analysis and Reporting' = 8,
  // 'Human Resources and Recruitment' = 9,
  // 'Marketing and Social Media Management' = 10,
  // 'Remote Work Tools' = 11,
  // 'Creative and Design Tools' = 12,
  // 'Legal and Compliance' = 13,
  // 'Educational and Training' = 14,
  // 'Customer Support and Help Desk' = 15,
  // 'Cybersecurity and Privacy' = 16,
  // 'Inventory and Supply Chain Management' = 17,
  // 'Travel and Expense Management' = 18,
  // 'Health and Wellness' = 19,
  // 'Other' = 20
}

export const WorkCategoryObject = {
  'Core Work': 1,
  'Supportive Work': 2,
  'Communication and Meetings': 3,
  'Learning': 4,
  'Personal and Social': 5,
  'Breaks': 6,
  'Other': 7,
  'Uncategorised': 0,

  // 'Communication and Collaboration' : 1,
  // 'Productivity and Organization' : 2,
  // 'Document and File Management' : 3,
  // 'CRM and Sales' : 4,
  // 'Project Management and Planning' : 5,
  // 'Finance and Accounting': 6,
  // 'Technical and Development Tools' : 7,
  // 'Data Analysis and Reporting': 8,
  // 'Human Resources and Recruitment' : 9,
  // 'Marketing and Social Media Management': 10,
  // 'Remote Work Tools' : 11,
  // 'Creative and Design Tools' : 12,
  // 'Legal and Compliance' : 13,
  // 'Educational and Training' : 14,
  // 'Customer Support and Help Desk' : 15,
  // 'Cybersecurity and Privacy' : 16,
  // 'Inventory and Supply Chain Management' : 17,
  // 'Travel and Expense Management' : 18,
  // 'Health and Wellness' : 19,
  // 'Other': 20
}


export enum WorkCategoryColors {
  'Core Work' = '#2F7DD3',
  'Supportive Work' = '#86FAF2',
  'Communication and Meetings' = '#FF7621',
  'Learning' = '#D5297F',
  'Personal and Social' = '#21C47B',
  'Breaks' = '#F3CF26',
  'Other' = '#761CD4',
  'Uncategorised' = '#A9A9A9',
  'Unknown' = '#A9A9A9'
}

// export enum WorkCategoryColors {
//   'Miscellaneous' = '#606468',
//   'Core Work' = '#2F7DD3',
//   'Supportive Work' = '#FF7621',
//   'Communication and Meetings' = '#D5297F',
//   'Wellness' = '#21C47B',
//   'Learning' = '#F3CF26',
//   'Breaks' = '#86FAF2',
//   'Creativity' = '#761CD4',
// }
