import { ChangeDetectionStrategy, Component, EventEmitter, Optional, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormGroup, ReactiveFormsModule, ValidatorFn, AbstractControl } from '@angular/forms';
import { BehaviorSubject, map, shareReplay, tap } from 'rxjs';
import { InviteService } from '../../invite.service';
import { ProjectService } from 'app/services/project.service';
import { ProjectRoles, ProjectStatus } from 'app/models/project/project';
import { FuseCardModule } from '@fuse/components/card';
import { MaterialModule } from 'app/core/material/material.module';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'tm-invite-form',
  templateUrl: './invite-form.component.html',
  styleUrls: ['./invite-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FuseCardModule, MaterialModule, ReactiveFormsModule, CommonModule]
})
export class InviteFormComponent {
  readonly submitting$ = new BehaviorSubject<boolean>(false);
  readonly submitted$ = new BehaviorSubject<boolean>(false);
  readonly inviteForm = this.fb.group({
    members: this.fb.array([this.generateMemberToForm])
  });

  readonly projects$ = this.projectService.projects$.pipe(
    map((projects) => projects.filter(project => project.status !== ProjectStatus.Archived)),
    tap((projects) => {
      if (projects.length > 0) {
        this.getMember(0).get('projectId').setValue(projects[0].id);
      }
    }),
    shareReplay(1)
  );

  constructor(
    @Optional() private readonly dialogRef: MatDialogRef<InviteFormComponent>,
    private readonly fb: FormBuilder,
    private inviteService: InviteService,
    private readonly projectService: ProjectService,
    private readonly snackBar: MatSnackBar) { }

  projectValidator(): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const projectId = formGroup.get('projectId').value;
      const projectRole = formGroup.get('projectRole').value;
      if (projectId !== null && projectRole === null) {
        formGroup.get('projectRole').setErrors({ isRoleRequired: true })
      } else {
        formGroup.get('projectRole').setErrors(null)
      }
      return null;
    }
  }

  get generateMemberToForm(): AbstractControl {
    return this.fb.group({
      projectId: [null],
      projectRole: [ProjectRoles.Member],
      email: [null, [Validators.required, Validators.email]],
      displayName: [null],
    }, { validators: this.projectValidator() });
  }

  get getFormMembers(): FormArray {
    return this.inviteForm.get("members") as FormArray;
  }

  addNewMember(): void {
    this.getFormMembers.push(this.generateMemberToForm);
  }

  removeMember(index: number): void {
    this.getFormMembers.removeAt(index);
  }

  copyMember(index: number): void {
    const member = this.getMember(index).value;
    const newMember = this.generateMemberToForm;
    newMember.patchValue({
      ...member
    });
    this.getFormMembers.push(newMember);
  }

  getMember(index: number): FormGroup {
    return this.getFormMembers.at(index) as FormGroup;
  }

  clearForm(): void {
    this.inviteForm.reset();
  }

  submit(): void {
    this.submitting$.next(true);
    const data = {
      list: [...this.getFormMembers.value]
    };
    this.inviteService.sendInviteToTeam(data).subscribe({
      next: () => {
        this.submitting$.next(false);
        this.submitted$.next(true);
        this.dialogRef?.close(true);
        const msg = (data.list.length > 1) ? 'Users have been invited!' : 'A User has been invited!'
        this.snackBar.open(msg, 'Ok', { duration: 5000 });
      },
      error: () => {
        this.submitting$.next(false);
        this.submitted$.next(false);
      }
    });
  }
}
