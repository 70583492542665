import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { Observable, Subject, of, timer } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AppInfoComponent } from './modules/app-service/components/app-info/app-info.component';
import { UpdateAppService } from './services/update-app.service';
import { SignalRService } from './services/signal-r.service';
import { IsNewVersionMsgAppComponent } from './shared/components/is-new-version-msg-app/is-new-version-msg-app.component';

@Component({
  selector: 'tm-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    // @Inject(WINDOW) private readonly window: Window,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private snackbar: MatSnackBar,
    private readonly updateAppService: UpdateAppService,
    private readonly signalRService: SignalRService,
    private readonly snackBar: MatSnackBar,
  ) { }

  private checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnInit(): void {
    this.snackbar.openFromComponent(AppInfoComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    });

    this.updateAppService.isLoadNewVersion$.pipe(
      takeUntil(this._destroying$),
      filter(isNewVersion => isNewVersion),
    ).subscribe(() => this.snackBar.openFromComponent(IsNewVersionMsgAppComponent));

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this._destroying$),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        take(1),
      ).subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        // console.log('ACQUIRE_TOKEN_SUCCESS');
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      ).subscribe((result: EventMessage) => {
        if (result.error && result.error.message.indexOf('AADB2C90091') > -1) {
          window.location.replace("/user"); // microsoft crutch issues#5800
        }
      });

    this.signalRService.startConnection().pipe(
      takeUntil(this._destroying$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}