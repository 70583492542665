import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { secondsToTime } from 'app/utils/seconds-to-time';
import { ApexOptions } from 'ng-apexcharts';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DataChart } from 'app/models/dashboards/data-chart';
import { Timesheet } from 'app/models/timesheet';
import { WorkCategoryColors } from 'app/models/category/category';
import { NgxSkeletonLoaderConfig } from 'ngx-skeleton-loader';
import { SkeletonConfigService } from 'app/services/skeleton-config.service';


const colors = ['#008FFB', '#FEB019', '#FF4560', '#775DD0', '#00E396'];
function getColor(name: string, idx: number) {
  return WorkCategoryColors[name] ?? colors[idx]
}

@Component({
  selector: 'tm-top-five-chart',
  templateUrl: './top-five-chart.component.html',
  styleUrls: ['./top-five-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopFiveChartComponent {
  readonly isLoadData$ = new BehaviorSubject<boolean>(false);
  @Input() sparkline = true;
  @Input() title = '';
  @Input() set data(value: DataChart<Timesheet>) {
    this.isLoadData$.next(value.isLoadData)
    if (value.isLoadData) {
      this.prepareChart(value.data);
    }
  }
  chartData: ApexOptions;

  constructor(private readonly skeletonConfigService: SkeletonConfigService) { }

  get skeletonConfigCircle$(): Observable<NgxSkeletonLoaderConfig> {
    return this.skeletonConfigService.skeletonConfig$.pipe(
      map(config => ({
        ...config,
        theme: {
          ...config.theme,
          'border-radius': '50%',
          width: '170px',
          height: '170px'
        }
      }))
    );
  }

  get skeletonConfig$(): Observable<NgxSkeletonLoaderConfig> {
    return this.skeletonConfigService.skeletonConfig$.pipe(
      map(config => ({
        ...config,
        theme: {
          ...config.theme,
          'border-radius': '5px',
          height: '40px'
        }
      }))
    );
  }

  private prepareChart(statistic: any) {
    let isData = statistic?.length > 0;
    const labels = statistic.map(item => item.name);
    const series = statistic.map(item => item.timeWorked);
    const colors = statistic.map((item, idx) => getColor(item.name, idx));
    this.chartData = {
      chart: {
        animations: {
          enabled: true,
          speed: 400,
          animateGradually: {
            enabled: false
          },
        },
        fontFamily: 'inherit',
        foreColor: 'inherit',
        height: '100%',
        type: 'donut',
        sparkline: {
          enabled: this.sparkline
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      colors: isData ? colors : ['#ccc'],
      labels: isData ? labels : [],
      series: isData ? series : [100],
      tooltip: {
        enabled: isData,
        fillSeriesColor: false,
        theme: 'dark',
        y: {
          formatter: (v) => secondsToTime(v)
        }
      }
    };
  }
}