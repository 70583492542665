<div [formGroup]="mainForm">
  <mat-vertical-stepper #stepper [linear]="true">
    <mat-step [stepControl]="organizationFG">
      <div formGroupName="organization">
        <ng-template matStepLabel>Company Name</ng-template>
        <div class="text-secondary text-md font-normal my-4">Enter the name of your company. This will be used to identify your account and personalize your experience throughout the platform.</div>
        <mat-form-field class="w-full mb-2">
          <mat-label>Enter the name of your company</mat-label>
          <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:business'" matPrefix></mat-icon>
          <input [formControlName]="'name'" matInput cdkFocusInitial>
          <mat-error>Company name is required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>Description</mat-label>
          <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:description'" matPrefix></mat-icon>
          <input [formControlName]="'description'" matInput>
        </mat-form-field>
        <div class="mt-2">
          <button [disabled]="organizationFG.invalid" color="primary" mat-raised-button matStepperNext>
            <div class="flex items-center gap-2">
              <ng-container *ngIf="submitting$ | async" [ngTemplateOutlet]="spinner"></ng-container>
              <div>Continue</div>
            </div>
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="industryFG">
      <div formGroupName="industry">
        <ng-template matStepLabel>Industry</ng-template>
        <div class="text-secondary text-md font-normal my-4">Select the industry that best represents your organisations’s focus. This will help us tailor our services and features to match your specific needs and goals.</div>
        <mat-form-field class="w-full">
          <mat-label>Select the industry</mat-label>
          <mat-select formControlName="name">
            <mat-option [value]="undustry.id" *ngFor="let undustry of idustryList">{{undustry.name}}</mat-option>
          </mat-select>
          <mat-error>Industry is required</mat-error>
        </mat-form-field>
        <div class="mt-2">
          <button [disabled]="industryFG.invalid || (submitting$ | async)" color="primary"
            mat-raised-button (click)="organizationCreate()">
            <div class="flex items-center gap-2">
              <ng-container *ngIf="submitting$ | async" [ngTemplateOutlet]="spinner"></ng-container>
              <div>Complete Registration</div>
            </div>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>

<ng-template #spinner>
  <mat-spinner [color]="'primary'" diameter="20"></mat-spinner>
</ng-template>