import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'app/core/material/material.module';
import { BehaviorSubject, Subject, map, takeUntil, tap, catchError, of, Observable, merge, shareReplay, combineLatest } from 'rxjs';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { SecondsToPercentPipe } from './pipes/seconds-to-percent.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientState, ClientStatus } from 'app/models/client/client-state';
import { DateTime } from 'luxon';
import { isSupportApp } from 'app/utils/support-app';
import { CLIENT_STATE } from 'app/services/client.state';
import { OBJECTSSESSION, ObjectKindUI, ObjectSession } from 'app/services/objects-session';
import { TimeTrackingService } from 'app/services/time-tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'tm-time-tracking-widget',
  standalone: true,
  imports: [CommonModule, MaterialModule, SecondsToTimePipe, SecondsToPercentPipe, FormsModule, ReactiveFormsModule, DragDropModule, RouterModule, MatTooltipModule],
  templateUrl: './time-tracking-widget.component.html',
  styleUrls: ['./time-tracking-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TimeTrackingWidgetComponent implements OnInit, OnDestroy {
  tooltip$ = new BehaviorSubject<string>('');
  objectsList = [];
  // readonly dragPosition$ = new BehaviorSubject<{ x: number, y: number }>({ x: 0, y: 0 });
  // @ViewChild('timeTrackerDialog') public timeTrackerDialog: TemplateRef<any>;
  // private _dialog: MatDialogRef<TemplateRef<any>>;
  private readonly destroy$ = new Subject<void>();
  private readonly _error$ = new BehaviorSubject<string | undefined>(undefined);
  // readonly isSupportApp = isSupportApp();
  // readonly objectFC = new FormControl<{ id: string, objectKind: ObjectKind }>(null, Validators.required);
  // readonly objects$ = this.objectsSessions$.pipe(
  //   map(objects => objects.reduce((acc, curr) => {
  //     if (acc[curr.category]) {
  //       acc[curr.category].push(curr);
  //     } else acc[curr.category] = [curr];
  //     return acc;
  //   }, {})),
  //   map((objects: { [key: string]: Array<ObjectSession> }) => {
  //     let data = {};
  //     for (const [key, value] of Object.entries(objects)) {
  //       data[`${key}`] = value.sort((v1, v2) => v1.title.localeCompare(v2.title))
  //     }
  //     return data;
  //   })
  // );

  readonly durationActive$ = this.timeTrackingService.durationActive$;

  get duration$() {
    return this.timeTrackingService.duration$;
  }

  readonly errors$ = merge(this.timeTrackingService.error$, this._error$).pipe(
    shareReplay(1)
  );


  get isPlayValue() {
    return this.timeTrackingService.isPlayValue;
  }

  get isPlay$() {
    return this.timeTrackingService.isPlay$;
  }

  // get objectId() {
  //   return this.objectFC.value.id;
  // }

  // get objectKind() {
  //   return this.objectFC.value.objectKind;
  // }

  // readonly state$ = this.clientState$.pipe(
  //   map(state => state.client),
  //   tap(({ session, status, timeWorked, committedAt }) => {
  //     this._error$.next(null);
  //     this.timeTrackingService.init(timeWorked, committedAt);
  //     if (session) {
  //       // this.objectFC.setValue({ id: session.objectId, objectKind: session.objectKind }, { emitEvent: false });;
  //       if (status === ClientStatus.Started) {
  //         this.timeTrackingService.run(session.id, committedAt, session.startedAt);
  //       }
  //     }
  //   },
  //   ),
  //   catchError((err) => {
  //     const date = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
  //     this.timeTrackingService.init(0, date);
  //     this._error$.next('Error get state!');
  //     return of(true);
  //   })
  // );

  readonly state$ = combineLatest({
    session: this.clientState$.pipe(
      map(state => state.client),
      map(({ session, status, timeWorked, committedAt }) => {
        this._error$.next(null);
        this.timeTrackingService.init(timeWorked, committedAt);
        if (session) {
          if (status === ClientStatus.Started) {
            this.timeTrackingService.run(session.id, committedAt, session.startedAt);
          }
        }
        return ({ objectId: session?.objectId, status });
      })),
    objectsSessions: this.objectsSessions$.pipe(tap(objectsSessions => {
      this.objectsList = objectsSessions;
    }))
  }).pipe(
    tap(({ session, objectsSessions }) => (session.status === ClientStatus.Started) ? this.setTooltip(session.objectId) : this.tooltip$.next('')),
    catchError((err) => {
      const date = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
      this.timeTrackingService.init(0, date);
      this._error$.next('Error get state!');
      return of(true);
    })
  );

  constructor(
    @Inject(CLIENT_STATE) private readonly clientState$: Observable<ClientState>,
    @Inject(OBJECTSSESSION) readonly objectsSessions$: Observable<Array<ObjectSession>>,
    private readonly timeTrackingService: TimeTrackingService,
    private readonly dialog: MatDialog,
  ) { }


  // dragEnded(event: CdkDragEnd) {
  //   let element = event.source.getRootElement();
  //   let boundingClientRect = element.getBoundingClientRect();
  //   let parentPosition = this.getPositionModal(element);
  //   console.log(boundingClientRect);
  //   // console.log(boundingClientRect.y);
  //   localStorage.setItem('ttw-x', `${boundingClientRect.x - parentPosition.left}`);
  //   localStorage.setItem('ttw-y', `${boundingClientRect.y - parentPosition.top}`);

  //   localStorage.setItem('ttwp-x', `${(boundingClientRect.x - parentPosition.left) / (window.innerWidth - boundingClientRect.width)}`);
  //   localStorage.setItem('ttwpy', `${(boundingClientRect.y - parentPosition.top) / (window.innerHeight - boundingClientRect.height)}`);
  //   localStorage.setItem('ttwp-x', `${(boundingClientRect.x - parentPosition.left) / (window.innerWidth)}`);
  //   localStorage.setItem('ttwp-y', `${(boundingClientRect.y - parentPosition.top) / (window.innerHeight)}`);
  // }

  // private getPositionModal(el) {
  //   let x = 0;
  //   let y = 0;
  //   while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
  //     x += el.offsetLeft - el.scrollLeft;
  //     y += el.offsetTop - el.scrollTop;
  //     el = el.offsetParent;
  //   }
  //   return { top: y, left: x };
  // }

  // play() {
  //   if (this.objectFC.value) {
  //     const startDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
  //     this.timeTrackingService.play(this.objectId, this.objectKind, startDate);
  //   } else this.openModal();
  // }

  // stop() {
  //   const endDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
  //   this.timeTrackingService.stop(endDate);
  // }

  // openModal() {
  //   if (this.dialog) {
  //     this.dialog.closeAll();
  //   }
  //   this._dialog = this.dialog.open(this.timeTrackerDialog, { hasBackdrop: false, /*position: {bottom: '0', right: '0'} */ }).addPanelClass(['min-w-60', 'max-w-full', 'w-full', 'h-full', 'sm:h-fit', 'sm:w-60', 'md:w-80']);
  // }

  // compare(o1: ObjectSession, o2: ObjectSession) {
  //   return o1?.id === o2?.id;
  // }

  private setTooltip(objectId: string) {
    const selectedObject = this.objectsList.find(object => object.id === objectId);
    const tooltipMsg = `Active ${ObjectKindUI[selectedObject?.objectKind]}: ${selectedObject?.title}`;
    this.tooltip$.next(tooltipMsg);
  }

  ngOnInit(): void {

    // this.fuseMediaWatcherService.onMediaChange$.pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe((v) => {
    //   console.log(v, window.innerHeight, window.innerWidth)
    //   // localStorage.setItem('ttw-x', `0`);
    //   // localStorage.setItem('ttw-y', `0`);
    //   // this.dragPosition$.next({ x: 0, y: 0 });
    //   // this._dialog?.updatePosition();
    // });

    this.timeTrackingService.clientStarted$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ objectId, sessionId, startedAt, objectKind }) => {
        // this.objectFC.setValue({ id: objectId, objectKind: objectKind }, { emitEvent: false });
        this.setTooltip(objectId);
        if (!this.isPlayValue) {
          this.timeTrackingService.run(sessionId, startedAt);
        }
      }
    });

    this.timeTrackingService.clientTimeWorkedUpdated$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ timeWorked }) => this.timeTrackingService.updateDuration(timeWorked)
    });

    this.timeTrackingService.clientStopped$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ sessionId, timeWorked, stoppedAt }) => {
        this.tooltip$.next('');
        this.timeTrackingService.init(timeWorked, stoppedAt);
      },
      error: (err) => {
        this._error$.next(err);
      }
    });

    this.timeTrackingService.clientSessionObjectChanged$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: ({ objectId }) => {
        this.setTooltip(objectId);
      },
      error: (err) => {
        this._error$.next(err);
      }
    });


    // this.objectFC.valueChanges.pipe(
    //   takeUntil(this.destroy$),
    //   filter(() => this.isPlayValue),
    //   debounceTime(300),
    //   distinctUntilChanged(),
    //   switchMap((object) => this.timeTrackingService.сhangeObject(object.id, object.objectKind))
    // ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}