import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, map, shareReplay, tap } from 'rxjs';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { AssignRoles } from 'app/models/roles';
import { DashboardIndividual } from 'app/models/dashboards/dashboard-individual';
import { CurrentUser, User, UserGlobalRole } from 'app/models/user/user';
import { OrganizationService } from './organization.service';
import { Timesheet, TimesheetList } from 'app/models/timesheet';
import { UserOrganizationRole } from 'app/models/organization/organization';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly user$$ = new ReplaySubject<User>(1);
  user: User = null;

  constructor(private _httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: AppConfig, private readonly organizationService: OrganizationService) { }

  get user$(): Observable<User> {
    return this.user$$.asObservable();
  }

  get isOrganizationOwner$(): Observable<boolean> {
    return this.user$.pipe(
      map(user => user.role === UserOrganizationRole.Owner || user.role === UserOrganizationRole.Viewer || false),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  get userId(): string {
    return this.user?.id;
  }

  get isSuperAdmin$(): Observable<boolean> {
    return this.user$.pipe(
      map(user => /*user?.role === UserGlobalRole.SuperAdmin ||*/ false)
    );
  }

  setUser(user: User) {
    this.user$$.next(user);
  }

  getAccount(): Observable<CurrentUser> {
    return this._httpClient.get<{ user: CurrentUser }>(`${this.appConfig.baseUrl}/users/me`).pipe(
      map(account => account.user)
    );
  }

  viewUser(id: string): Observable<User> {
    return this._httpClient.get<User>(`${this.appConfig.baseUrl}/users/${id}`);
  }

  assignRoles(roles: AssignRoles) {
    return this._httpClient.put(`${this.appConfig.oldBaseUrl}/api/admin/users/roles`, roles);
  }

  remove(userId: number) {
    return this._httpClient.delete(`${this.appConfig.oldBaseUrl}/api/users/${userId}/1`);
  }

  updateProfile(data: { displayName?: string, firstName?: string, lastName?: string, countryCode?: string }): Observable<unknown> {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/users/${this.userId}`, data);
  }

  getActivity(params: HttpParams): Observable<DashboardIndividual> {
    return this._httpClient.get<DashboardIndividual>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/chart`, { params });
  }

  roleAssign(userId: string, role: UserOrganizationRole) {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users/${userId}`, { role });
  }

  updateJobRole(jobRole: number): Observable<any> {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users/${this.userId}`, { jobRole });
  }

  categoryAssign(userId: string, categoryId: string) {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users/${userId}`, { categoryId });
  }

  getTimesheet(userId: string, params: HttpParams): Observable<Array<Timesheet>> {
    return this._httpClient.get<TimesheetList>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users/${userId}/timesheet`, { params }).pipe(
      map((timesheet) => timesheet.list)
    );
  }

  completeOnboard(data: { completedSteps: Array<string>, completed: boolean, skipped: boolean }): Observable<unknown> {
    return this._httpClient.put<any>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/users/${this.userId}/onboarding`, data);
  }
}