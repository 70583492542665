import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, defer, filter, from, map, retry, shareReplay, switchMap, tap } from 'rxjs';
import { SignalRService } from './signal-r.service';
import { ObjectKind } from './objects-session';
import { OrganizationService } from './organization.service';
import { UUID } from 'angular2-uuid';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimeTrackingService {
  private _activeSessionStart: string = new Date().toJSON();
  private _worker: Worker;
  static DELTA = 60;
  private readonly _isPlay$ = new BehaviorSubject<boolean>(false);
  private _timeWorkedState = 0;
  sessionId: string | null = null;
  private readonly _duration$ = new BehaviorSubject<number>(0);
  private readonly _durationLocal$ = new BehaviorSubject<number>(0);
  private readonly _error$ = new BehaviorSubject<string | null>(null);
  private readonly _currentDate$ = new BehaviorSubject<DateTime>(DateTime.now());

  constructor(
    private readonly signalService: SignalRService,
    private readonly organizationService: OrganizationService,
  ) {
    this.initSW();
  }

  get duration$() {
    return this._duration$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  readonly durationActive$ = this.currentDate$.pipe(
    map(currentDate => currentDate.diff(DateTime.fromISO(this._activeSessionStart), 'seconds').seconds),
    filter(duration => duration > 0)
  );

  get error$() {
    return this._error$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get currentDate$() {
    return this._currentDate$.pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlay$() {
    return this._isPlay$.asObservable().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get isPlayValue() {
    return this._isPlay$.value;
  }
  get isConnected$() {
    return this.signalService.isConnected$.pipe(
      tap((isConnected) => {
        if (!!isConnected)
          this._error$.next(null);
      })
    );
  }
  get isReconnected$() {
    return this.signalService.isReconnected$;
  }
  get clientStarted$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, startedAt: string, connectId: string }>((observer) => {
        this.signalService.hubConnection.on('ClientStarted', (orgId, sessionId, objectId, objectKind, startedAt, connectId) => {
          observer.next({ orgId, sessionId, objectId, objectKind, startedAt, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get clientStopped$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, stoppedAt: string, timeWorked: number, connectId: string, }>((observer) => {
        this.signalService.hubConnection.on('ClientStopped', (orgId, sessionId, stoppedAt, timeWorked, connectId) => {
          observer.next({ orgId, sessionId, stoppedAt, timeWorked, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get clientTimeWorkedUpdated$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, timeWorked: number, commitedAtt: string }>((observer) => {
        this.signalService.hubConnection.on('ClientTimeWorkedUpdated', (orgId, timeWorked, commitedAtt) => {
          observer.next({ orgId, timeWorked, commitedAtt });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get clientSessionObjectChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ orgId: string, sessionId: string, objectId: string, objectKind: ObjectKind, connectId: string }>((observer) => {
        this.signalService.hubConnection.on('ClientSessionObjectChanged', (orgId, sessionId, objectId, objectKind, connectId) => {
          observer.next({ orgId, sessionId, objectId, objectKind, connectId });
        });
      }))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  get sessionCreated$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionCreated', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get sessionSubjectChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionSubjectChanged', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get sessionResized$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionResized', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get sessionSwitched$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionSwitched', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
  get sessionDeleted$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<void>((observer) => {
        this.signalService.hubConnection.on('SessionDeleted', () => {
          observer.next();
        });
      }))),
      // retry({ delay: () => this.isReconnected$ }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private startSession(objectId: string, objectKind: ObjectKind, sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.hubConnection?.invoke('StartSession', orgId, objectId, objectKind, sessionId, date));
  }

  private endSession(sessionId: string, date: string): Observable<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return from(this.signalService.hubConnection?.invoke('EndSession', orgId, sessionId, date));
  }

  commitSession(sessionId: string, date: string): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('CommitTime', orgId, sessionId, date) ?? Promise.reject(new Error('not connection'));
  }

  сhangeObject(objectId: string, objectKind: ObjectKind): Promise<any> {
    const orgId = this.organizationService.organizationSelectedId;
    return this.signalService.hubConnection?.invoke('ChangeObject', orgId, this.sessionId, objectId, objectKind) ?? Promise.reject(new Error('not connection'));
  }

  init(timeWorked: number, date: string) {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked);
  }

  play(objectId: string, objectKind: ObjectKind, date: string): Observable<any> {
    this._activeSessionStart = date;
    this.sendToWorker('stop', date);
    this.sessionId = UUID.UUID();
    this._isPlay$.next(true);
    this.sendToWorker('start', date);
    // this.startSession(objectId, objectKind, this.sessionId, date).then((data: { isSusscess: boolean, statusCode: number, error?: { title: string, detail: string } }) => {
    //   if (data.statusCode === 409) {
    //     this._isPlay$.next(false);
    //     this.sendToWorker('stop', date);
    //     this._duration$.next(this._timeWorkedState);
    //     this._error$.next('409: conflict');
    //   }
    // }).catch((err) => {
    //   this._isPlay$.next(false);
    //   this.sendToWorker('stop', date);
    //   this._duration$.next(this._timeWorkedState);
    //   this._error$.next(err);
    // });
    return this.startSession(objectId, objectKind, this.sessionId, date).pipe(
      tap({
        next: (data) => {
          if (data.statusCode === 409) {
            this._isPlay$.next(false);
            this.sendToWorker('stop', date);
            this._duration$.next(this._timeWorkedState);
            this._error$.next('409: conflict');
          }
        },
        error: (err) => {
          this._isPlay$.next(false);
          this.sendToWorker('stop', date);
          this._duration$.next(this._timeWorkedState);
          this._error$.next(err);
        }
      }),
      retry({ count: 2, delay: 2000 })
    );
  }

  stop(date: string): Observable<any> {
    this._isPlay$.next(false);
    this.sendToWorker('stop', date);
    // this.endSession(this.sessionId, date).then(
    //   () => {
    //     this.sessionId = null;
    //     this._timeWorkedState = this._duration$.value;
    //   }).catch((err) => this._error$.next(err));
    return this.endSession(this.sessionId, date).pipe(
      tap({
        next: () => {
          this.sessionId = null;
          this._timeWorkedState = this._duration$.value;
        },
        error: (err) => this._error$.next(err)
      }),
      retry({ count: 2, delay: 2000 })
    );
  }

  private commit() {
    const commitDate = DateTime.now().set({ millisecond: 0 }).toUTC().toISO({ suppressMilliseconds: true });
    this.commitSession(this.sessionId, commitDate);
  }

  private sendToWorker(event: 'start' | 'stop', data: string) {
    this._worker.postMessage({ event, date: data });
  }

  run(sessionId: string, committedAt: string, starttedAt?: string) {
    this._activeSessionStart = starttedAt || committedAt;
    this.sendToWorker('stop', committedAt);
    this.sessionId = sessionId;
    this._isPlay$.next(true);
    const timeDifference = DateTime.now().set({ millisecond: 0 }).toUTC().diff(DateTime.fromISO(committedAt), 'seconds').seconds;
    this._duration$.next(timeDifference + this._timeWorkedState);
    this.sendToWorker('start', committedAt);
  }

  updateDuration(timeWorked: number) {
    this._timeWorkedState = timeWorked;
    this._duration$.next(timeWorked + this._durationLocal$.value);
  }

  private initSW() {
    if (typeof Worker !== 'undefined') {
      this._worker = new Worker(new URL('/src/app/time-tracking.worker', import.meta.url), { name: 'TimeTracking', type: 'module' });
      this._worker.onerror = (error) => {
        console.error('An error occurred in the worker:', error);
      };
      this._worker.addEventListener('message', (message: MessageEvent) => {
        const duration = message.data;
        this.tick(duration);
      });
    } else {
      this._error$.next('web worker not supported!')
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }
  }

  private tick(duration: number) {
    this._currentDate$.next(DateTime.now());
    const duarationAll = this._timeWorkedState + duration;
    this._duration$.next(duarationAll);
    if (duration % TimeTrackingService.DELTA === 0)
      this.commit();
  }
}