import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, CanMatch, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, map, tap, retry, catchError } from 'rxjs';
import { OnboardingComponent } from './onboarding.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrganizationService } from 'app/services/organization.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanDeactivate<OnboardingComponent>, CanActivate, CanMatch {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly organizationService: OrganizationService,
    private readonly router: Router
  ) { }
  canDeactivate(component: OnboardingComponent): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return component.progress$.pipe(
      tap(progress => {
        if (progress !== 100) {
          this.snackBar.open('Please, fill out all cards or marked onboarding complete!', '', { duration: 5000 })
        }
      }),
      map(progress => progress === 100),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.organizationService.organizationView(this.organizationService.organizationSelectedId).pipe(
      // tap({
      //   error: (error: HttpErrorResponse) => {
      //     if (error instanceof HttpErrorResponse && (error.status === 404)) {
      //       localStorage.setItem('organizationSelected', '');
      //     }
      //   }
      // }),
      // retry({ count: 1, delay: 1000 }),
      map((organization) => {
        if (!organization?.onboarding?.completed && organization?.isOwner) {
          return true;
        } else this.router.navigateByUrl('organization');
      })
    )
  }

  canMatch(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.organizationService.organizationView(this.organizationService.organizationSelectedId).pipe(
      map((organization) => !organization?.onboarding?.completed && organization?.isOwner),
    )
  }
}
