<div class="flex justify-center w-full">
<div class="flex flex-col gap-4 w-full max-w-screen-xl mx-auto px-6 sm:px-8 py-6">
  <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug">
    Welcome to SpectroTime</div>
  <div class="text-secondary text-lg">Before diving in, we've outlined a few simple steps for you to complete. Taking a
    moment to finish these will ensure you get the most out of our app. Once done, you'll be all set to start exploring.
    Let's get you onboarded and ready to go!</div>
  <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-1 sm:gap-0 mt-4">
    <a name="/#create_project" class="flex gap-2 items-center" [ngClass]="(isCreateProject$ | async) ? 'text-primary-500' : 'text-secondary'">
      <mat-icon [color]="(isCreateProject$ | async) ? 'primary' : ''"
        [svgIcon]="(isCreateProject$ | async) ? 'mat_outline:check_circle' : 'mat_outline:add_business'"></mat-icon>
      <span class="font-semibold text-lg">Create a Project</span>
    </a>
    <a name="/#invite_users" class="flex gap-2 items-center" [ngClass]="(isInviteUsers$ | async) ? 'text-primary-500' : 'text-secondary'">
      <mat-icon [color]="(isInviteUsers$ | async) ? 'primary' : ''"
        [svgIcon]="(isInviteUsers$ | async) ? 'mat_outline:check_circle' : 'mat_outline:group_add'"></mat-icon>
      <span class="font-semibold text-lg">Invinite Users</span>
    </a>
    <a name="/#assign_task" class="flex gap-2 items-center" [ngClass]="(isCreateTask$ | async) ? 'text-primary-500' : 'text-secondary'">
      <mat-icon [color]="(isCreateTask$ | async) ? 'primary' : ''"
        [svgIcon]="(isCreateTask$ | async) ? 'mat_outline:check_circle' : 'mat_outline:task'"></mat-icon>
      <span class="font-semibold text-lg">Assign a Task</span>
    </a>
    <a name="/#download_app" class="flex gap-2 items-center" [ngClass]="(isDownloadApp$ | async) ? 'text-primary-500' : 'text-secondary'">
      <mat-icon [color]="(isDownloadApp$ | async) ? 'primary' : ''"
        [svgIcon]="(isDownloadApp$ | async) ? 'mat_outline:check_circle' : 'mat_outline:download'"></mat-icon>
      <span class="font-semibold text-lg">Download an App</span>
    </a>
  </div>
  <mat-progress-bar mode="determinate" [value]="progress$ | async"></mat-progress-bar>
  <div class="flex flex-col justify-start gap-8 lg:flex-row lg:items-stretch mt-4">
    <div class="flex flex-col items-center justify-start flex-1 gap-8 md:flex-row md:justify-between md:items-stretch" >
      <fuse-card id="create_project" class="flex flex-col items-center justify-between flex-1 w-full p-8 relative">
        <mat-icon *ngIf="isCreateProject$ | async" matTooltip="Comleted" class="absolute top-4 right-4 icon-size-8 text-green-700 self-end"
          svgIcon="mat_outline:check"></mat-icon>
        <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600">
          <mat-icon class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'mat_outline:add_business'"></mat-icon>
        </div>
        <div>
          <div class="text-2xl font-semibold leading-tight text-center mt-6">Create a Project</div>
          <div class="text-center text-secondary mt-3"> Set up a new project by providing a name and adding a brief
            description.</div>
        </div>
        <button class="px-6 mt-8" mat-flat-button [color]="'primary'" (click)="createProject()">
          <mat-icon svgIcon="mat_outline:add"></mat-icon>
          <span class="ml-1">Add Project</span>
        </button>
      </fuse-card>
      <fuse-card id="invite_users" class="flex flex-col items-center justify-between flex-1 w-full p-8 relative">
        <mat-icon *ngIf="isInviteUsers$ | async" matTooltip="Comleted" class="absolute top-4 right-4 icon-size-8 text-green-700 self-end"
          svgIcon="mat_outline:check"></mat-icon>
        <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600">
          <mat-icon class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'mat_outline:group_add'"></mat-icon>
        </div>
        <div>
          <div class="text-2xl font-semibold leading-tight text-center mt-6">Invite Users</div>
          <div class="text-center text-secondary mt-3">Invite team members to join you on the platform. Enter their
            email
            addresses, and
            they'll receive an invite to get started.</div>
        </div>
        <button class="px-6 mt-8" mat-flat-button [color]="'primary'" (click)="inviteUsers()">
          <mat-icon svgIcon="mat_outline:send"></mat-icon>
          <span class="ml-1">Invite Users</span>
        </button>
      </fuse-card>
    </div>
    <div class="flex flex-col items-center justify-start flex-1 gap-8 md:flex-row md:justify-between md:items-stretch">
      <fuse-card id="assign_task" class="flex flex-col items-center justify-between flex-1 w-full p-8 relative">
        <mat-icon *ngIf="isCreateTask$ | async" matTooltip="Comleted" class="absolute top-4 right-4 icon-size-8 text-green-700 self-end"
          svgIcon="mat_outline:check"></mat-icon>
        <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600">
          <mat-icon class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'mat_outline:task'"></mat-icon>
        </div>
        <div>
          <div class="text-2xl font-semibold leading-tight text-center mt-6">Assign a Task</div>
          <div class="text-center text-secondary mt-3">Ensure efficient workflow by designating specific tasks to your
            team members.</div>
        </div>
        <button class="px-6 mt-8" mat-flat-button [color]="'primary'" (click)="addTask()">
          <mat-icon svgIcon="mat_outline:add_task"></mat-icon>
          <span class="ml-1">Assign a Task</span>
        </button>
      </fuse-card>
      <fuse-card id="download_app" class="flex flex-col items-center justify-between flex-1 w-full p-8 relative">
        <mat-icon *ngIf="isDownloadApp$ | async" matTooltip="Comleted" class="absolute top-4 right-4 icon-size-8 text-green-700 self-end"
          svgIcon="mat_outline:check"></mat-icon>
        <div class="flex items-center justify-center p-5 rounded-full bg-primary-100 dark:bg-gray-600">
          <mat-icon class="icon-size-10 text-primary-500 dark:text-primary-300"
            [svgIcon]="'mat_outline:download'"></mat-icon>
        </div>
        <div>
          <div class="text-2xl font-semibold leading-tight text-center mt-6">Download an App</div>
          <div class="text-center text-secondary mt-3">Download an app and start tracking time.</div>
        </div>
        <button class="px-6 mt-8" mat-flat-button [color]="'primary'" (click)="downloadApp()">
          <mat-icon svgIcon="mat_outline:window"></mat-icon>
          <span class="ml-1">Download an App</span>
        </button>
      </fuse-card>
    </div>
  </div>
  <mat-divider class="my-4"></mat-divider>
  <button class="self-start" mat-stroked-button color="primary" [disabled]="submitting$ | async" (click)="complete()">Mark Onboarding Complete</button>
</div>
</div>


<ng-template #infoProject>
  <div class="flex items-center gap-2">
    <mat-icon class="icon-size-6 text-white dark:text-black" svgIcon="mat_outline:info"></mat-icon>
    <span class="dark:text-black">Please, add project!</span>
  </div>
</ng-template>