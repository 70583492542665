import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ClientState } from 'app/models/client/client-state';
import { NewSession, Session, Sessions } from 'app/models/sessions/session';
import { OrganizationService } from './organization.service';
import { map, Observable, Subject, switchMap, take } from 'rxjs';
import { DateTime } from 'luxon';
import { splitSessions } from 'app/modules/user/modules/reports/base/simple';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class ClientService {
  readonly refreshState$ = new Subject<void>();

  constructor(
    private _httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly organizationService: OrganizationService,
    private readonly userService: UserService
  ) { }


  clientState(userId: string) {
    return this._httpClient.get<ClientState>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/clients/${userId}/state`);
  }

  getUserSessions(start: string, end?: string) {
    return this.userService.user$.pipe(
      take(1),
      switchMap(({ id }) => this.getSessions(start, end, id))
    );
  }

  getSessions(start: string, end?: string, userId?: string): Observable<Array<Session>> {
    let params = new HttpParams();
    if (start) {
      params = params.append('startTime', start);
    }
    if (end) {
      params = params.append('endTime', end);
    }
    if (userId) {
      params = params.append('userId', userId);
    }
    return this._httpClient.get<Sessions>(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions`, { params }).pipe(
      map(data => data.list),
      map(sessions => sessions.map(session => ({
        ...session,
        originStartTime: session.startTime,
        originEndTime: session.endTime
      }))),
      map((sessions) => sessions.map(session => {
        const startTime = DateTime.fromISO(session.startTime);
        const endTime = DateTime.fromISO(session.endTime);
        const gueryStartTime = DateTime.fromISO(start);
        if (startTime < gueryStartTime) {
          session.startTime = gueryStartTime.toISO();
        }
        const gueryEndTime = DateTime.fromISO(end || DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISO());
        if (endTime > gueryEndTime) {
          session.endTime = gueryEndTime.toISO();
        }
        session.duration = DateTime.fromISO(session.endTime).diff(DateTime.fromISO(session.startTime), 'seconds').seconds;
        return session;
      })),
      map((sessions) => splitSessions(sessions))
    );
  }

  addSession(session: NewSession) {
    return this._httpClient.post(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions`, session);
  }

  changeSession(id: string, session: NewSession) {
    return this._httpClient.patch(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions/${id}`, session);
  }

  removeSession(id: string) {
    return this._httpClient.delete(`${this.appConfig.baseUrl}/organizations/${this.organizationService.organizationSelectedId}/sessions/${id}`);
  }
}
