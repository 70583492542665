import { ChangeDetectorRef, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import { Session } from "app/models/sessions/session";
import { ClientService } from "app/services/client.service";
import { DateTime } from "luxon";
import { BehaviorSubject, Subject } from "rxjs";

export function splitSessions(sessions: Array<Session> = []): Array<Session> {
  const result: Array<Session> = [];
  let flag = false;
  for (let i = 0; i < sessions.length; i++) {
    const startTime = DateTime.fromISO(sessions[i].startTime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endTime = DateTime.fromISO(sessions[i].endTime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (startTime < endTime) {
      const endTime = DateTime.fromISO(sessions[i].startTime).set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
      const startTime = DateTime.fromISO(sessions[i].startTime).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 });
      const newSessions = [
        {
          ...sessions[i],
          duration: +endTime.diff(DateTime.fromISO(sessions[i].startTime), 'seconds').seconds,
          endTime: endTime.toISO(),
        },
        {
          ...sessions[i],
          startTime: startTime.toISO(),
          duration: DateTime.fromISO(sessions[i].endTime).diff(startTime, 'seconds').seconds,
        }
      ];
      result.push(...newSessions);
      flag = true;
    } else {
      result.push(sessions[i]);
    }
  }
  if (flag) {
    return splitSessions([...result]);
  }
  return result;
}

export abstract class SimpleReport {
  protected abstract path: string
}

export class SimpleBase extends SimpleReport {
  protected readonly clientService = inject(ClientService);
  protected readonly loading$ = new BehaviorSubject<boolean>(true);
  protected readonly destroy$ = new Subject<void>();
  protected path = '';
  protected readonly activatedRoute = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly fuseMediaWatcherService = inject(FuseMediaWatcherService);
  protected readonly chd = inject(ChangeDetectorRef);
}