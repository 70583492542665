import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil, shareReplay, filter, switchMap } from 'rxjs';
import { UserService } from 'app/services/user.service';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { User } from 'app/models/user/user';
import { OrganizationService } from 'app/services/organization.service';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationAddComponent } from 'app/modules/organization/modules/settings/components/organization-add/organization-add.component';


@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
  @Input() showAvatar: boolean = true;
  user: User;
  private destroy$: Subject<any> = new Subject<void>();
  organizations$ = this.organizationService.organizations$.pipe(shareReplay(1));

  get selectedOrganizationId(): string {
    return this.organizationService.organizationSelectedId;
  }

  constructor(
    private readonly organizationService: OrganizationService,
    private chr: ChangeDetectorRef,
    private _userService: UserService,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly dialog: MatDialog) { }

  selectOrganization(id: string) {
    this.organizationService.select(id);
  }

  addOrganization(): void {
    const dialogRef = this.dialog.open(OrganizationAddComponent);
    dialogRef.afterClosed().pipe(
      filter(isUpdate => isUpdate),
      switchMap(() => this.organizationService.get())
    ).subscribe();
  }

  signOut(): void {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  ngOnInit(): void {
    this._userService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        this.user = user;
        this.chr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}