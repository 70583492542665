import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { MaterialModule } from 'app/core/material/material.module';
import { OrganizationAddFormComponent } from '../../components/organization-add-form/organization-add-form.component';
import { CommonModule } from '@angular/common';
import { OrganizationService } from 'app/services/organization.service';
import { FuseCardModule } from '@fuse/components/card';

@Component({
  selector: 'tm-registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    OrganizationAddFormComponent,
    FuseCardModule
  ]
})
export class RegistrationCompleteComponent {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private readonly organizationService: OrganizationService) {
  }

  done(organizationId: string): void {
    this.organizationService.select(organizationId);
  }

  signOut(): void {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }
}