import { Pipe, PipeTransform } from '@angular/core';
import { secondsToTime, secondsToTime2 } from 'app/utils/seconds-to-time';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {

  transform(seconds: number): string {
    return secondsToTime2(seconds);
  }
}
