export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'f09bce14-43fe-4025-b862-27a727e6810c',
    },
    scopes: [
      'https://devcogentime.onmicrosoft.com/user-api/user.read',
      'https://devcogentime.onmicrosoft.com/user-api/user.write',
      // 'https://devcogentime.onmicrosoft.com/user-api/offline_access',
    ],
  },
  apiConfig: {
    oldBaseUrl: 'https://dev-timeter-api.azurewebsites.net',
    baseUrl: 'https://api-dev.spectrotime.com/v1'
  },
  hubConnection: 'https://hub-dev.spectrotime.com/web',
  b2cPolicies: {
    names: {
      signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
      signUp: "B2C_1A_SIGNUP",
      resetPassword: "B2C_1_PasswordReset",
    },
    authorities: {
      signUpSignIn: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1A_SIGNUP_SIGNIN'
      },
      signUp: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1A_SIGNUP'
      },
      resetPassword: {
        authority: 'https://id-dev.spectrotime.com/a1b90253-a8a7-4cf6-8324-a82a1c0c6e0d/B2C_1_PasswordReset'
      },
    },
    authorityDomain: "id-dev.spectrotime.com"
  }
};