import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil, Subject, BehaviorSubject, tap } from 'rxjs';

@Component({
  selector: 'tm-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFailedComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  readonly isAuth$ = new BehaviorSubject<boolean>(true);
  constructor(
    // @Inject(MSAL_GUARD_CONFIG)
    // private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    // private readonly router: Router,
    // private msalBroadcastService: MsalBroadcastService
  ) { }

  // login() {
  //   if (this.msalGuardConfig.authRequest) {
  //     this.authService.loginRedirect({
  //       ...this.msalGuardConfig.authRequest,
  //     } as RedirectRequest);
  //   } else {
  //     this.authService.loginRedirect();
  //   }
  // }

  logout() {
    this.authService.logoutRedirect();
  }


  // signOut() {
  //   this.authService.logoutRedirect().pipe(
  //     takeUntil(this.destroy$)
  //   );
  // }

  // private checkAndSetActiveAccount() {
  //   const activeAccount = this.authService.instance.getActiveAccount();
  //   if (activeAccount || this.authService.instance.getAllAccounts().length > 0) {
  //     const accounts = this.authService.instance.getAllAccounts();
  //     this.authService.instance.setActiveAccount(activeAccount || accounts[0]);
  //     this.router.navigateByUrl('/dashboards');
  //   } else this.isAuth$.next(false);
  // }

  ngOnInit(): void {
    // this.msalBroadcastService.inProgress$.pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.None),
    //   takeUntil(this.destroy$)
    // ).subscribe({
    //   next: () => this.checkAndSetActiveAccount(),
    //   error: (err) => {
    //     console.error(err);
    //     // localStorage.clear();
    //     // this.logout();
    //     this.isAuth$.next(false);
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}