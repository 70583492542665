import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService } from 'app/services/organization.service';
import { UserService } from 'app/services/user.service';
import { Organization } from 'app/models/organization/organization';
import { Subject, takeUntil, Observable } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { EditCategoryComponent } from 'app/shared/components/edit-category/edit-category.component';

@Component({
  selector: 'tm-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  organizations: Organization[] = [];
  selectedOrganization: Organization;


  constructor(
    public dialog: MatDialog,
    private readonly _organizationService: OrganizationService,
    private readonly chr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly userService: UserService
  ) { }

  get isOwnerOrg$(): Observable<boolean> {
    return this.userService.isOrganizationOwner$;
  }

  selectOrganization(id: string) {
    this._organizationService.select(id);
  }

  addCategory(): void {
    this.dialog.open(EditCategoryComponent, { autoFocus: false }).addPanelClass(['max-w-full', 'w-full', 'h-full', 'sm:h-fit', 'sm:w-60', 'lg:w-80']);
  }

  get selectedOrganizationId(): string {
    return this._organizationService.organizationSelectedId;
  }

  ngOnInit(): void {
    this._organizationService.organizations$.pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: (organizations) => {
        this.organizations = organizations;
        this.chr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}