<div class="relative">
  <div
    class="flex items-center gap-1 sm:gap-2 pl-2 sm:pl-3.5 pr-4 py-2 sm:py-3 bg-primary-700 text-white rounded-full shadow-lg min-w-60 cursor-move"
    cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".theme-default">
    <ng-container *ngIf="(state$ | async); else spinnerTmpl">
      <div class="min-w-14">
        <ng-container [ngTemplateOutlet]="(isPlay$ | async) ? stopBtnTmpl : playBtnTmpl"></ng-container>
      </div>
      <div class="flex-auto min-w-0" *ngIf="selectedObject$ | async as selectedObject">
        <div class="flex gap-1 sm:gap-2 items-center text-lg md:text-xl flex-auto leading-5">
          <div class="cursor-pointer hover:bg-primary-800 rounded-md gap-2 pl-3 pr-1 flex items-center min-w-0"
            [matMenuTriggerFor]="menu" [matTooltip]="selectedObject?.title" #menuTr="matMenuTrigger">
            <div class="truncate">{{selectedObject?.title}}</div>
            <mat-icon svgIcon="more_horiz" class="text-white icon-size-8"></mat-icon>
          </div>
          <div *ngIf="isPlayValue" class="font-semibold  text-lg md:text-2xl w-24 text-end ml-auto">
            {{durationActive$ | async | secondsToTimePipe}}
          </div>
        </div>
        <div class="flex gap-3 items-center flex-auto pl-3">
          <div class="flex items-center gap-2">
            <div *ngIf="isPlayValue" class="text-sm leading-5 flex items-center gap-1">
              <mat-icon class="text-white icon-size-6" svgIcon="mat_outline:access_time"></mat-icon>
              <div class="flex items-center h-3 leading-3"><span>{{startedAt | date:
                  'HH:mm'}}</span><span>-</span><span>{{currentDate | date:'HH:mm'}}</span></div>
            </div>
            <div class="flex gap-2 items-center" *ngIf="selectedObject?.objectKind === 'Project'">
              <div class="flex items-center gap-1">
                <mat-icon class="icon-size-6 text-white" svgIcon="mat_outline:folder"></mat-icon>
                <span class="text-md">{{selectedObject?.objectKind}}</span>
              </div>
            </div>
          </div>
          <div class="hidden sm:flex gap-1 items-center ml-auto text-lg leading-4">
            <div>Today:</div>
            <div class="font-medium w-18 text-end">
              {{duration$ | async | secondsToTimePipe}}
            </div>
          </div>
        </div>
      </div>
        <mat-icon *ngIf="dialogRef" class="icon-size-5 text-white cursor-pointer" [svgIcon]="'close'" (click)="dialogRef?.close()"></mat-icon>
    </ng-container>
  </div>
  <div class="text-xs text-warn-500 pl-2 sm:pl-5" *ngIf="errors$ | async as errors">
    {{errors}}
  </div>
</div>
<ng-template #playBtnTmpl>
  <button class="w-14 h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 shadow-md"
    mat-ripple (click)="play()">
    <mat-icon class="icon-size-8 text-primary-700" svgIcon="mat_solid:play_arrow"
      [matTooltip]="'Start tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #stopBtnTmpl>
  <button class="w-14 h-14 rounded-full flex justify-center items-center bg-white border border-gray-300 shadow-md"
    mat-ripple (click)="stop()">
    <mat-icon class="icon-size-8 text-primary-700" svgIcon="mat_solid:stop" [matTooltip]="'Stop tracking'"></mat-icon>
  </button>
</ng-template>

<ng-template #spinnerTmpl>
  <div class="flex flex-auto items-center py-2 justify-center">
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
     <mat-spinner diameter="30"></mat-spinner>
  </div>
</ng-template>

<mat-menu #menu="matMenu" class="min-w-40">
  <button *ngIf="projectsList.length > 0" mat-menu-item [matMenuTriggerFor]="projectsMenu">Projects</button>
  <button *ngIf="ticketsList.length > 0" mat-menu-item [matMenuTriggerFor]="ticketsMenu">Tasks</button>
  <!-- <mat-divider></mat-divider> -->
  <button mat-menu-item (click)="createTicket()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Add Task</span>
  </button>
  <button mat-menu-item (click)="sessionNewAdd()">
    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
    <span>Log Manual Time</span>
  </button>
</mat-menu>

<mat-menu #projectsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let project of projectsList" (click)="changeObject(project)"
    [disabled]="project.id === (selectedObject$ | async)?.id">{{project.title}}</button>
</mat-menu>

<mat-menu #ticketsMenu="matMenu" class="max-h-120">
  <button mat-menu-item *ngFor="let ticket of ticketsList" (click)="changeObject(ticket)"
    [disabled]="ticket.id === (selectedObject$ | async)?.id">{{ticket.title}}</button>
</mat-menu>