import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';
import { MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'tm-sign-up',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent implements OnInit {
  constructor(private readonly authService: MsalService) { }

  ngOnInit(): void {
    let signUpRequest: RedirectRequest = {
      authority: environment.b2cPolicies.authorities.signUp.authority,
      scopes: [...environment.msalConfig.scopes],
      redirectStartPage: '/'
    }
    this.authService.loginRedirect(signUpRequest);
  }
}