import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AppConfigModule } from './app-config.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalModule } from './msal/msal.module';
import { LayoutModule } from './layout/layout.module';
import { CommonComponentsModule } from './shared/common-components.module';
import 'hammerjs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AppServiceModule } from './modules/app-service/app-service.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { FuseLoadingInterceptor } from '@fuse/services/loading/loading.interceptor';
import { SignUpComponent } from './shared/pages/sign-up/sign-up.component';
import { RegistrationCompleteComponent } from './modules/organization/modules/settings/pages/registration-complete/registration-complete.component';
import { SignOutComponent } from './shared/pages/sign-out/sign-out.component';
import { IsNewVersionMsgAppComponent } from './shared/components/is-new-version-msg-app/is-new-version-msg-app.component';


@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
}

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  // useHash: false,
  // anchorScrolling: 'enabled',
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    CoreModule,
    LayoutModule,
    AppConfigModule,
    MsalModule,
    CommonComponentsModule,
    HammerModule,
    AppServiceModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RegistrationCompleteComponent,
    SignUpComponent,
    SignOutComponent,
    IsNewVersionMsgAppComponent
  ],
  providers: [
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FuseLoadingInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }