import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { environment } from 'environments/environment';
import { Observable, ReplaySubject, Subject, catchError, defer, delay, filter, lastValueFrom, merge, of, retry, shareReplay, switchMap, take, tap, throwError } from 'rxjs';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private timerId;
  private readonly _reconnected$ = new Subject<void>();
  private readonly _reconnect$ = new Subject<void>();
  hubConnection: signalR.HubConnection = this.createHubConnection();
  private readonly _isConnect$ = new ReplaySubject<true>(1);
  private readonly _errorConnection$ = new Subject<void>();
  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly organizationService: OrganizationService,
    private readonly authService: MsalService,
  ) { }

  private reconnect() {
    this._reconnect$.next();
  }

  get isConnected$() {
    return merge(
      this._isConnect$.asObservable(),
      this._errorConnection$.asObservable().pipe(
        switchMap(() => throwError(() => new Error("SignalR connection error!")))
      )
    );
  }

  get isReconnected$() {
    return this._reconnected$.asObservable();
  }

  get onlineChanged$() {
    return this.isConnected$.pipe(
      switchMap(() => defer(() => new Observable<{ id: string, online: boolean }>((observer) => this.hubConnection.on('UserOnlineChanged', (id, online) => observer.next({ id, online }))))),
      retry({ delay: () => this.isReconnected$ }),
      shareReplay(1),
      catchError(() => of(null))
    );
  }

  private createHubConnection() {
    return new HubConnectionBuilder()//.configureLogging(LogLevel.Trace)
      .withUrl(`${environment.hubConnection}`, {
        accessTokenFactory: () => {
          const accessTokenRequest = {
            scopes: environment.msalConfig.scopes,
            account: this.authService.instance.getActiveAccount(),
          };
          return lastValueFrom(this.authService.acquireTokenSilent(accessTokenRequest))
            .then(data => data.accessToken)
            .catch((error) => {
              if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                this.authService.acquireTokenRedirect(accessTokenRequest).subscribe();
                return null;
              }
            });
        }
      })
      .withAutomaticReconnect([2, 4, 20])
      .build();
  }

  private connectionErrorHandler(err?: any) {
    console.error(err);
    this.reconnect();
  }

  public startConnection() {
    return merge(
      this._reconnect$.pipe(delay(10000)),
      this.msalBroadcastService.msalSubject$.pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        take(1)
      )
    ).pipe(
      // tap(() => clearTimeout(this.timerId)),
      switchMap(() => {
        return this.hubConnection.start().then(() => {
          console.log('SignalR connected');
          this.hubConnection.onclose(() => {
            this.hubConnection = this.createHubConnection();
            console.log('SignalR close')
            this._errorConnection$.next();
            this.connectionErrorHandler();
          });
        }).catch((err: any) => {
          this._errorConnection$.next();
          console.error('Error while starting connection: ' + err);
          this.connectionErrorHandler(err);
        });
      }),
      tap({
        next: () => {
          this.hubConnection.invoke('Subscribe', this.organizationService.organizationSelectedId).then(() => {
            console.log('Subscribe');
            this._isConnect$.next(true);
            this._reconnected$.next();
          });
        },
      }),
      // retry({ delay: () => this._errorConnection$ })
    );
  }

}