import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { OrganizationService } from 'app/services/organization.service';
import { Observable, tap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCompleteGuard implements CanActivate {

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly router: Router
  ) { }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.organizationService.getOrganizations().pipe(
      map((organizations) => {
        if (organizations?.length === 0) {
          return true;
        } else this.router.navigateByUrl('organization');
      })
    )
  }
}
